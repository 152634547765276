export default {
  translation: {
    common: {
      indonesia: 'Indonesia',
      delete: 'Hapus',
      deleteModalTitle: 'Yakin untuk menghapus item ini?',
      ok: 'Ya',
      cancel: 'Tidak',
      total: 'Total',
      rename: 'Ubah nama',
      name: 'Nama',
      save: 'Simpan',
      namePlaceholder: 'Silakan masukkan nama',
      next: 'Lanjutkan',
      create: 'Buat',
      edit: 'Ubah',
      upload: 'Unggah',
      english: 'Inggris',
      chinese: 'Cina',
      traditionalChinese: 'Cina Tradisional',
      language: 'Bahasa',
      languageMessage: 'Silakan masukkan bahasa Anda!',
      languagePlaceholder: 'Pilih bahasa Anda',
      copy: 'Salin',
      copied: 'Disalin',
      comingSoon: 'Segera Hadir',
      download: 'Unduh',
      gdriveConnect: 'Sambungkan ke Google Drive',
      downloading: 'Mengunduh File',
      downloadFailed: 'Unduh Gagal',
      downloaded: 'File Terunduh',
      close: 'Tutup',
      preview: 'Pratinjau',
      move: 'Pindahkan',
      warn: 'Peringatan',
    },
    login: {
      login: 'Masuk',
      signUp: 'Daftar',
      loginDescription: 'Kami sangat senang melihat Anda kembali!',
      registerDescription: 'Senang memiliki Anda di sini!',
      emailLabel: 'Email',
      emailPlaceholder: 'Silakan masukkan email',
      passwordLabel: 'Kata Sandi',
      passwordPlaceholder: 'Silakan masukkan kata sandi',
      rememberMe: 'Ingat saya',
      signInTip: 'Belum punya akun?',
      signUpTip: 'Sudah punya akun?',
      nicknameLabel: 'Nama Panggilan',
      nicknamePlaceholder: 'Silakan masukkan nama panggilan',
      register: 'Buat akun',
      continue: 'Lanjutkan',
      title: 'Mulai membangun asisten pintar Anda.',
      description:
        'Daftar gratis untuk menjelajahi teknologi RAG teratas. Buat basis pengetahuan dan AI untuk memberdayakan bisnis Anda.',
      review: 'dari 500+ ulasan',
    },
    header: {
      knowledgeBase: 'Basis Pengetahuan',
      chat: 'Obrolan',
      register: 'Daftar',
      signin: 'Masuk',
      home: 'Beranda',
      setting: 'Pengaturan Pengguna',
      logout: 'Keluar',
      fileManager: 'Manajemen File',
      cloud: 'Unggahan Cloud',
      flow: 'Agen',
      search: 'Cari',
    },
    knowledgeList: {
      welcome: 'Selamat datang kembali',
      description: 'Basis pengetahuan mana yang akan kita gunakan hari ini?',
      createKnowledgeBase: 'Buat basis pengetahuan',
      name: 'Nama',
      namePlaceholder: 'Silakan masukkan nama!',
      doc: 'Dokumen',
      searchKnowledgePlaceholder: 'Cari',
    },
    knowledgeDetails: {
      dataset: 'Dataset',
      testing: 'Pengujian pengambilan',
      files: 'file',
      configuration: 'Konfigurasi',
      name: 'Nama',
      namePlaceholder: 'Silakan masukkan nama!',
      doc: 'Dokumen',
      datasetDescription:
        '😉 Pertanyaan dan jawaban hanya dapat dijawab setelah parsing berhasil.',
      addFile: 'Tambah file',
      searchFiles: 'Cari file Anda',
      localFiles: 'File lokal',
      emptyFiles: 'Buat file kosong',
      webCrawl: 'Penjelajahan Web',
      chunkNumber: 'Jumlah Potongan',
      uploadDate: 'Tanggal Unggah',
      chunkMethod: 'Metode Potongan',
      enabled: 'Aktifkan',
      disabled: 'Nonaktifkan',
      action: 'Aksi',
      parsingStatus: 'Status Parsing',
      processBeginAt: 'Proses Dimulai Pada',
      processDuration: 'Durasi Proses',
      progressMsg: 'Pesan Kemajuan',
      testingDescription:
        'Final step! After success, leave the rest to Kecilin-Team/assistxsuite/ AI.',
      similarityThreshold: 'Similarity threshold',
      similarityThresholdTip:
        'Kami menggunakan skor kesamaan hibrida untuk mengevaluasi jarak antara dua baris teks. Ini adalah kesamaan kata kunci berbobot dan kesamaan kosinus vektor. Jika kesamaan antara kueri dan potongan kurang dari ambang ini, potongan akan disaring.',
      vectorSimilarityWeight: 'Bobot kesamaan kata kunci',
      vectorSimilarityWeightTip:
        'Kami menggunakan skor kesamaan hibrida untuk mengevaluasi jarak antara dua baris teks. Ini adalah kesamaan kata kunci berbobot dan kesamaan kosinus vektor atau skor rerank (0~1). Jumlah dari kedua bobot adalah 1.0.',
      testText: 'Teks uji',
      testTextPlaceholder: 'Silakan masukkan pertanyaan Anda!',
      testingLabel: 'Pengujian',
      similarity: 'Kesamaan Hibrida',
      termSimilarity: 'Kesamaan Istilah',
      vectorSimilarity: 'Kesamaan Vektor',
      hits: 'Hits',
      view: 'Lihat',
      filesSelected: 'File Terpilih',
      upload: 'Unggah',
      run: 'Menguraikan',
      runningStatus0: 'Belum Terselesaikan',
      runningStatus1: 'Parsing',
      runningStatus2: 'BATAL',
      runningStatus3: 'SUKSES',
      runningStatus4: 'GAGAL',
      pageRanges: 'Rentang Halaman',
      pageRangesTip:
        'rentang halaman: Tentukan rentang halaman yang perlu diparsing. Halaman yang tidak termasuk dalam rentang ini akan diabaikan.',
      fromPlaceholder: 'dari',
      fromMessage: 'Nomor halaman awal hilang',
      toPlaceholder: 'ke',
      toMessage: 'Nomor halaman akhir hilang (tidak termasuk)',
      layoutRecognize: 'Pengenalan tata letak',
      layoutRecognizeTip:
        'Gunakan model visual untuk analisis tata letak untuk lebih mengidentifikasi struktur dokumen, menemukan di mana judul, blok teks, gambar, dan tabel berada. Tanpa fitur ini, hanya teks biasa dari PDF yang dapat diperoleh.',
      taskPageSize: 'Ukuran halaman tugas',
      taskPageSizeMessage: 'Silakan masukkan ukuran halaman tugas Anda!',
      taskPageSizeTip: `Jika menggunakan pengenalan tata letak, file PDF akan dibagi menjadi kelompok berturut-turut. Analisis tata letak akan dilakukan secara paralel antar kelompok untuk meningkatkan kecepatan pemrosesan. 'Ukuran halaman tugas' menentukan ukuran kelompok. Semakin besar ukuran halaman, semakin kecil kemungkinan teks berkelanjutan antara halaman dibagi menjadi potongan yang berbeda.`,
      addPage: 'Tambah halaman',
      greaterThan: 'Nilai saat ini harus lebih besar dari!',
      greaterThanPrevious: 'Nilai saat ini harus lebih besar dari sebelumnya!',
      selectFiles: 'Pilih file',
      changeSpecificCategory: 'Ubah kategori spesifik',
      uploadTitle: 'Klik atau seret file ke area ini untuk mengunggah',
      uploadDescription:
        'Dukungan untuk unggahan tunggal atau massal. Dilarang keras mengunggah data perusahaan atau file terlarang lainnya.',
      chunk: 'Potongan',
      bulk: 'Massal',
      cancel: 'Batal',
      rerankModel: 'Model Rerank',
      rerankPlaceholder: 'Silakan pilih',
      rerankTip: `Jika kosong. Ini menggunakan embedding dari kueri dan potongan untuk menghitung kesamaan kosinus vektor. Jika tidak, ini menggunakan skor rerank sebagai pengganti kesamaan kosinus vektor.`,
      topK: 'Top-K',
      topKTip: `K potongan akan dimasukkan ke dalam model rerank.`,
      delimiter: `Pembatas`,
      html4excel: 'Excel ke HTML',
      html4excelTip: `Excel akan diparsing menjadi tabel HTML atau tidak. Jika FALSE, setiap baris di Excel akan dibentuk sebagai potongan.`,
    },
    knowledgeConfiguration: {
      titleDescription:
        'Perbarui detail basis pengetahuan Anda terutama metode parsing di sini.',
      name: 'Nama basis pengetahuan',
      photo: 'Foto basis pengetahuan',
      description: 'Deskripsi',
      language: 'Bahasa',
      languageMessage: 'Silakan masukkan bahasa Anda!',
      languagePlaceholder: 'Silakan masukkan bahasa Anda!',
      permissions: 'Izin',
      embeddingModel: 'Model embedding',
      chunkTokenNumber: 'Jumlah token potongan',
      chunkTokenNumberMessage: 'Jumlah token potongan diperlukan',
      embeddingModelTip:
        'Model embedding yang digunakan untuk embedding potongan. Tidak dapat diubah setelah basis pengetahuan memiliki potongan. Anda perlu menghapus semua potongan jika ingin mengubahnya.',
      permissionsTip:
        "Jika izinnya 'Tim', semua anggota tim dapat memanipulasi basis pengetahuan.",
      chunkTokenNumberTip:
        'Ini menentukan jumlah token dari sebuah potongan secara kira-kira.',
      chunkMethod: 'Metode potongan',
      chunkMethodTip: 'Instruksi ada di sebelah kanan.',
      upload: 'Unggah',
      english: 'Inggris',
      chinese: 'Cina',
      embeddingModelPlaceholder: 'Silakan pilih model embedding',
      chunkMethodPlaceholder: 'Silakan pilih metode potongan',
      save: 'Simpan',
      me: 'Hanya saya',
      team: 'Tim',
      cancel: 'Batal',
      methodTitle: 'Deskripsi Metode Pemotongan',
      methodExamples: 'Contoh',
      methodExamplesDescription:
        'Cuplikan berikut disajikan untuk memudahkan pemahaman.',
      dialogueExamplesTitle: 'Contoh Dialog',
      methodEmpty:
        'Ini akan menampilkan penjelasan visual dari kategori basis pengetahuan',
      book: `<p>Format file yang didukung adalah <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
          Karena buku panjang dan tidak semua bagian berguna, jika itu adalah PDF,
          silakan atur <i>rentang halaman</i> untuk setiap buku untuk menghilangkan efek negatif dan menghemat waktu komputasi untuk analisis.</p>`,
      laws: `<p>Format file yang didukung adalah <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
          Dokumen hukum memiliki format penulisan yang sangat ketat. Kami menggunakan fitur teks untuk mendeteksi titik pemisah. 
          </p><p>
          Granularitas potongan konsisten dengan 'ARTIKEL', dan semua teks tingkat atas akan disertakan dalam potongan.
          </p>`,
      manual: `<p>Hanya <b>PDF</b> yang didukung.</p><p>
          Kami mengasumsikan manual memiliki struktur bagian hierarkis. Kami menggunakan judul bagian terendah sebagai poros untuk memotong dokumen.
          Jadi, gambar dan tabel dalam bagian yang sama tidak akan dipisahkan, dan ukuran potongan mungkin besar.
          </p>`,
      naive: `<p>Format file yang didukung adalah <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>
          <p>Metode ini menerapkan cara naif untuk memotong file: </p>
          <p>
          <li>Teks berturut-turut akan dipotong menjadi potongan menggunakan model deteksi visual.</li>
          <li>Selanjutnya, potongan berturut-turut ini digabungkan menjadi potongan yang jumlah tokennya tidak lebih dari 'Jumlah token'.</li></p>`,
      paper: `<p>Hanya file <b>PDF</b> yang didukung.</p><p>
          Jika model kami bekerja dengan baik, makalah akan dipotong berdasarkan bagiannya, seperti <i>abstrak, 1.1, 1.2</i>, dll. </p><p>
          Manfaat dari melakukan ini adalah LLM dapat lebih baik merangkum konten bagian yang relevan dalam makalah, 
          menghasilkan jawaban yang lebih komprehensif yang membantu pembaca lebih memahami makalah. 
          Kelemahannya adalah meningkatkan konteks percakapan LLM dan menambah biaya komputasi, 
          jadi selama percakapan, Anda dapat mempertimbangkan untuk mengurangi pengaturan ‘<b>topN</b>’.</p>`,
      presentation: `<p>Format file yang didukung adalah <b>PDF</b>, <b>PPTX</b>.</p><p>
          Setiap halaman akan diperlakukan sebagai potongan. Dan thumbnail setiap halaman akan disimpan.</p><p>
          <i>Semua file PPT yang Anda unggah akan dipotong menggunakan metode ini secara otomatis, pengaturan untuk setiap file PPT tidak diperlukan.</i></p>`,
      qa: `
          <p>
          Metode potongan ini mendukung format file <b>EXCEL</b> dan <b>CSV/TXT</b>.
        </p>
        <li>
          Jika file dalam format <b>Excel</b>, harus terdiri dari dua kolom
          tanpa header: satu untuk pertanyaan dan yang lainnya untuk jawaban, dengan
          kolom pertanyaan mendahului kolom jawaban. Lembar kerja ganda dapat diterima selama kolom terstruktur dengan benar.
        </li>
        <li>
          Jika file dalam format <b>CSV/TXT</b>, harus dikodekan UTF-8 dengan TAB
          digunakan sebagai pembatas untuk memisahkan pertanyaan dan jawaban.
        </li>
        <p>
          <i>
            Baris teks yang gagal mengikuti aturan di atas akan diabaikan, dan
            setiap pasangan Q&A akan dianggap sebagai potongan yang berbeda.
          </i>
        </p>
          `,
      resume: `<p>Format file yang didukung adalah <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
          </p><p>
          Resume datang dalam berbagai format, seperti kepribadian seseorang, tetapi kita sering harus mengaturnya menjadi data terstruktur yang memudahkan pencarian.
          </p><p>
          Alih-alih memotong resume, kami memparsing resume menjadi data terstruktur. Sebagai HR, Anda dapat membuang semua resume yang Anda miliki, 
          maka Anda dapat mencantumkan semua kandidat yang memenuhi kualifikasi hanya dengan berbicara dengan <i>'assistxsuite'</i>.
          </p>
          `,
      table: `<p>Format file yang didukung adalah <b>EXCEL</b> dan <b>CSV/TXT</b>.</p><p>
          Berikut beberapa tips:
          <ul>
        <li>Untuk file csv atau txt, pembatas antara kolom adalah <em><b>TAB</b></em>.</li>
        <li>Baris pertama harus berupa header kolom.</li>
        <li>Header kolom harus berupa istilah yang bermakna agar LLM kami dapat memahaminya.
        Sebaiknya enumerasikan beberapa sinonim menggunakan garis miring <i>'/'</i> untuk memisahkan, dan bahkan lebih baik untuk
        enumerasikan nilai menggunakan tanda kurung seperti <i>'gender/sex(male, female)'</i>.<p>
        Berikut beberapa contoh untuk header:<ol>
            <li>supplier/vendor<b>'TAB'</b>color(yellow, red, brown)<b>'TAB'</b>gender/sex(male, female)<b>'TAB'</b>size(M,L,XL,XXL)</li>
            <li>姓名/名字<b>'TAB'</b>电话/手机/微信<b>'TAB'</b>最高学历（高中，职高，硕士，本科，博士，初中，中技，中专，专科，专升本，MPA，MBA，EMBA）</li>
            </ol>
            </p>
        </li>
        <li>Setiap baris dalam tabel akan diperlakukan sebagai potongan.</li>
        </ul>`,
      picture: `
        <p>File gambar didukung. Video akan segera hadir.</p><p>
        Jika gambar memiliki teks di dalamnya, OCR diterapkan untuk mengekstrak teks sebagai deskripsi teksnya.
        </p><p>
        Jika teks yang diekstrak oleh OCR tidak cukup, LLM visual digunakan untuk mendapatkan deskripsi.
        </p>`,
      one: `
        <p>Format file yang didukung adalah <b>DOCX, EXCEL, PDF, TXT</b>.
        </p><p>
        Untuk dokumen, akan diperlakukan sebagai potongan utuh, tidak ada pemisahan sama sekali.
        </p><p>
        Jika Anda ingin merangkum sesuatu yang membutuhkan semua konteks dari sebuah artikel dan panjang konteks LLM yang dipilih mencakup panjang dokumen, Anda dapat mencoba metode ini.
        </p>`,
      knowledgeGraph: `<p>Format file yang didukung adalah <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>
    
    <p>Setelah file dipotong, digunakan potongan untuk mengekstrak grafik pengetahuan dan peta pikiran dari seluruh dokumen. Metode ini menerapkan cara naif untuk memotong file:
    Teks berturut-turut akan dipotong menjadi potongan masing-masing yang berjumlah sekitar 512 token.</p>
    <p>Selanjutnya, potongan akan dikirim ke LLM untuk mengekstrak node dan hubungan dari grafik pengetahuan, dan peta pikiran.</p>
    
    Perhatikan jenis entitas yang perlu Anda tentukan.</p>`,
      useRaptor: 'Gunakan RAPTOR untuk meningkatkan pengambilan',
      useRaptorTip:
        'Pemrosesan Abstraktif Rekursif untuk Pengambilan Terorganisasi Pohon, silakan merujuk ke https://huggingface.co/papers/2401.18059',
      prompt: 'Prompt',
      promptTip: 'Prompt LLM yang digunakan untuk merangkas.',
      promptMessage: 'Prompt diperlukan',
      promptText: `Silakan rangkum paragraf berikut. Berhati-hatilah dengan angka, jangan membuat hal-hal yang tidak ada. Paragraf sebagai berikut:
          {cluster_content}
    Di atas adalah konten yang perlu Anda rangkum.`,
      maxToken: 'Token maksimum',
      maxTokenTip: 'Jumlah token maksimum untuk peringkasan.',
      maxTokenMessage: 'Token maksimum diperlukan',
      threshold: 'Ambang batas',
      thresholdTip:
        'Semakin besar ambang batas, semakin sedikit kluster yang akan ada.',
      thresholdMessage: 'Ambang batas diperlukan',
      maxCluster: 'Kluster maksimum',
      maxClusterTip: 'Jumlah kluster maksimum.',
      maxClusterMessage: 'Kluster maksimum diperlukan',
      randomSeed: 'Benih acak',
      randomSeedMessage: 'Benih acak diperlukan',
      entityTypes: 'Jenis entitas',
    },
    chunk: {
      chunk: 'Potongan',
      bulk: 'Massal',
      selectAll: 'Pilih Semua',
      enabledSelected: 'Aktifkan yang Dipilih',
      disabledSelected: 'Nonaktifkan yang Dipilih',
      deleteSelected: 'Hapus yang Dipilih',
      search: 'Cari',
      all: 'Semua',
      enabled: 'Aktif',
      disabled: 'Nonaktif',
      keyword: 'Kata Kunci',
      function: 'Fungsi',
      chunkMessage: 'Silakan masukkan nilai!',
      full: 'Teks penuh',
      ellipse: 'Elips',
      graph: 'Grafik pengetahuan',
      mind: 'Peta pikiran',
    },
    chat: {
      newConversation: 'Percakapan baru',
      createAssistant: 'Buat Asisten',
      assistantSetting: 'Pengaturan Asisten',
      promptEngine: 'Mesin Prompt',
      modelSetting: 'Pengaturan Model',
      chat: 'Obrolan',
      newChat: 'Obrolan baru',
      send: 'Kirim',
      sendPlaceholder: 'Pesan ke Asisten...',
      chatConfiguration: 'Konfigurasi Obrolan',
      chatConfigurationDescription:
        'Di sini, dandani asisten khusus untuk basis pengetahuan khusus Anda! 💕',
      assistantName: 'Nama Asisten',
      assistantNameMessage: 'Nama asisten diperlukan',
      namePlaceholder: 'mis. Resume Jarvis',
      assistantAvatar: 'Avatar Asisten',
      language: 'Bahasa',
      emptyResponse: 'Respon kosong',
      emptyResponseTip: `Jika tidak ada yang diambil dengan pertanyaan pengguna di basis pengetahuan, ini akan digunakan sebagai jawaban. Jika Anda ingin LLM memberikan pendapatnya sendiri ketika tidak ada yang diambil, biarkan kosong.`,
      setAnOpener: 'Atur pembuka',
      setAnOpenerInitial: `Hai! Saya asisten Anda, apa yang bisa saya lakukan untuk Anda?`,
      setAnOpenerTip: 'Bagaimana Anda ingin menyambut klien Anda?',
      knowledgeBases: 'Basis Pengetahuan',
      knowledgeBasesMessage: 'Silakan pilih',
      knowledgeBasesTip: 'Pilih basis pengetahuan yang terkait.',
      system: 'Sistem',
      systemInitialValue: `Anda adalah asisten cerdas. Silakan rangkum konten basis pengetahuan untuk menjawab pertanyaan. Silakan daftar data di basis pengetahuan dan jawab secara detail. Ketika semua konten basis pengetahuan tidak relevan dengan pertanyaan, jawaban Anda harus menyertakan kalimat "Jawaban yang Anda cari tidak ditemukan di basis pengetahuan!" Jawaban perlu mempertimbangkan riwayat obrolan.
          Berikut adalah basis pengetahuan:
          {knowledge}
          Di atas adalah basis pengetahuan.`,
      systemMessage: 'Silakan masukkan!',
      systemTip:
        'Instruksi yang perlu diikuti LLM saat menjawab pertanyaan, seperti desain karakter, panjang jawaban, dan bahasa jawaban, dll.',
      topN: 'Top N',
      topNTip: `Tidak semua potongan yang skor kesamaannya di atas 'ambang kesamaan' akan diberikan ke LLM. LLM hanya dapat melihat potongan 'Top N' ini.`,
      variable: 'Variabel',
      variableTip: `Jika Anda menggunakan API dialog, variabel mungkin membantu Anda berbicara dengan klien Anda dengan strategi yang berbeda. 
          Variabel digunakan untuk mengisi bagian 'Sistem' dalam prompt untuk memberikan petunjuk kepada LLM.
          'knowledge' adalah variabel yang sangat khusus yang akan diisi dengan potongan yang diambil.
          Semua variabel dalam 'Sistem' harus diberi kurung kurawal.`,
      add: 'Tambah',
      key: 'Kunci',
      optional: 'Opsional',
      operation: 'Operasi',
      model: 'Model',
      modelTip: 'Model obrolan bahasa besar',
      modelMessage: 'Silakan pilih!',
      freedom: 'Kebebasan',
      improvise: 'Improvisasi',
      precise: 'Tepat',
      balance: 'Seimbang',
      freedomTip: `'Tepat' berarti LLM akan berhati-hati dan menjawab pertanyaan Anda dengan hati-hati. 'Improvisasi' berarti Anda ingin LLM berbicara banyak dan bebas. 'Seimbang' adalah antara berhati-hati dan bebas.`,
      temperature: 'Temperatur',
      temperatureMessage: 'Temperatur diperlukan',
      temperatureTip:
        'Parameter ini mengontrol keacakan prediksi oleh model. Temperatur yang lebih rendah membuat model lebih percaya diri dalam tanggapannya, sementara temperatur yang lebih tinggi membuatnya lebih kreatif dan beragam.',
      topP: 'Top P',
      topPMessage: 'Top P diperlukan',
      topPTip:
        'Juga dikenal sebagai “nucleus sampling,” parameter ini menetapkan ambang batas untuk memilih set kata yang lebih kecil untuk diambil sampelnya. Ini berfokus pada kata-kata yang paling mungkin, memotong yang kurang mungkin.',
      presencePenalty: 'Penalti Kehadiran',
      presencePenaltyMessage: 'Penalti Kehadiran diperlukan',
      presencePenaltyTip:
        'Ini mencegah model mengulangi informasi yang sama dengan memberikan penalti pada kata-kata yang sudah muncul dalam percakapan.',
      frequencyPenalty: 'Penalti Frekuensi',
      frequencyPenaltyMessage: 'Penalti Frekuensi diperlukan',
      frequencyPenaltyTip:
        'Mirip dengan penalti kehadiran, ini mengurangi kecenderungan model untuk mengulangi kata yang sama secara sering.',
      maxTokens: 'Token Maksimum',
      maxTokensMessage: 'Token Maksimum diperlukan',
      maxTokensTip:
        'Ini menetapkan panjang maksimum keluaran model, diukur dalam jumlah token (kata atau potongan kata).',
      maxTokensInvalidMessage:
        'Silakan masukkan angka yang valid untuk Max Tokens.',
      maxTokensMinMessage: 'Max Tokens tidak boleh kurang dari 0.',
      quote: 'Tampilkan Kutipan',
      quoteTip: 'Haruskah sumber teks asli ditampilkan?',
      selfRag: 'Self-RAG',
      selfRagTip:
        'Silakan merujuk ke: https://huggingface.co/papers/2310.11511',
      overview: 'ID Obrolan',
      pv: 'Jumlah pesan',
      uv: 'Jumlah pengguna aktif',
      speed: 'Kecepatan keluaran token',
      tokens: 'Konsumsi jumlah token',
      round: 'Jumlah Interaksi Sesi',
      thumbUp: 'kepuasan pelanggan',
      preview: 'Pratinjau',
      embedded: 'Tertanam',
      serviceApiEndpoint: 'Endpoint API Layanan',
      apiKey: 'Kunci API',
      apiReference: 'Dokumen API',
      dateRange: 'Rentang Tanggal:',
      backendServiceApi: 'API Server',
      createNewKey: 'Buat kunci baru',
      created: 'Dibuat',
      action: 'Aksi',
      embedModalTitle: 'Tanamkan ke situs web',
      comingSoon: 'Segera Hadir',
      fullScreenTitle: 'Tanamkan Penuh',
      fullScreenDescription:
        'Tanamkan iframe berikut ke situs web Anda di lokasi yang diinginkan',
      partialTitle: 'Tanamkan Parsial',
      extensionTitle: 'Ekstensi Chrome',
      tokenError: 'Silakan buat Token API terlebih dahulu!',
      searching: 'mencari...',
      parsing: 'Memparsing',
      uploading: 'Mengunggah',
      uploadFailed: 'Unggahan gagal',
      regenerate: 'Regenerasi',
      read: 'Baca konten',
      tts: 'Teks ke ucapan',
      ttsTip:
        'Untuk memutar suara menggunakan konversi suara, silakan pilih TTS (model konversi ucapan) di pengaturan terlebih dahulu.',
      relatedQuestion: 'Pertanyaan terkait',
      answerTitle: 'R',
      multiTurn: 'Optimasi multi-putaran',
      multiTurnTip:
        'Dalam percakapan multi-putaran, kueri ke basis pengetahuan dioptimalkan. Model besar akan dipanggil untuk mengonsumsi token tambahan.',
      languageSelectionTip: 'Pilih bahasa yang digunakan dalam percakapan.',
      description: 'Description of assistant',
    },
    setting: {
      profile: 'Profil',
      profileDescription: 'Perbarui foto dan detail pribadi Anda di sini.',
      maxTokens: 'Token Maksimum',
      maxTokensMessage: 'Token Maksimum diperlukan',
      maxTokensTip:
        'Ini menetapkan panjang maksimum keluaran model, diukur dalam jumlah token (kata atau potongan kata).',
      maxTokensInvalidMessage:
        'Silakan masukkan angka yang valid untuk Max Tokens.',
      maxTokensMinMessage: 'Max Tokens tidak boleh kurang dari 0.',
      password: 'Kata Sandi',
      passwordDescription:
        'Silakan masukkan kata sandi Anda saat ini untuk mengubah kata sandi Anda.',
      model: 'Penyedia Model',
      modelDescription: 'Atur parameter model dan Kunci API di sini.',
      team: 'Tim',
      system: 'Sistem',
      logout: 'Keluar',
      api: 'API',
      username: 'Nama Pengguna',
      usernameMessage: 'Silakan masukkan nama pengguna Anda!',
      photo: 'Foto Anda',
      photoDescription: 'Ini akan ditampilkan di profil Anda.',
      colorSchema: 'Skema Warna',
      colorSchemaMessage: 'Silakan pilih skema warna Anda!',
      colorSchemaPlaceholder: 'pilih skema warna Anda',
      bright: 'Terang',
      dark: 'Gelap',
      timezone: 'Zona Waktu',
      timezoneMessage: 'Silakan masukkan zona waktu Anda!',
      timezonePlaceholder: 'pilih zona waktu Anda',
      email: 'Alamat Email',
      emailDescription: 'Setelah terdaftar, E-mail tidak dapat diubah.',
      currentPassword: 'Kata sandi saat ini',
      currentPasswordMessage: 'Silakan masukkan kata sandi Anda!',
      newPassword: 'Kata sandi baru',
      newPasswordMessage: 'Silakan masukkan kata sandi Anda!',
      newPasswordDescription:
        'Kata sandi baru Anda harus lebih dari 8 karakter.',
      confirmPassword: 'Konfirmasi kata sandi baru',
      confirmPasswordMessage: 'Silakan konfirmasi kata sandi Anda!',
      confirmPasswordNonMatchMessage:
        'Kata sandi baru yang Anda masukkan tidak cocok!',
      cancel: 'Batal',
      addedModels: 'Model yang Ditambahkan',
      modelsToBeAdded: 'Model yang akan ditambahkan',
      addTheModel: 'Tambahkan model',
      apiKey: 'Kunci API',
      apiKeyMessage:
        'Silakan masukkan kunci API (untuk model yang diterapkan secara lokal, abaikan ini).',
      apiKeyTip:
        'Kunci API dapat diperoleh dengan mendaftar ke penyedia LLM yang sesuai.',
      showMoreModels: 'Tampilkan lebih banyak model',
      baseUrl: 'Base-Url',
      baseUrlTip:
        'Jika kunci API Anda berasal dari OpenAI, abaikan saja. Penyedia perantara lainnya akan memberikan base url ini dengan kunci API.',
      modify: 'Ubah',
      systemModelSettings: 'Pengaturan Model Sistem',
      chatModel: 'Model Obrolan',
      chatModelTip:
        'Model LLM obrolan default yang akan digunakan semua basis pengetahuan baru yang dibuat.',
      embeddingModel: 'Model Embedding',
      embeddingModelTip:
        'Model embedding default yang akan digunakan semua basis pengetahuan baru yang dibuat.',
      img2txtModel: 'Model Img2txt',
      img2txtModelTip:
        'Model multi-modul default yang akan digunakan semua basis pengetahuan baru yang dibuat. Ini dapat menggambarkan gambar atau video.',
      sequence2txtModel: 'Model Sequence2txt',
      sequence2txtModelTip:
        'Model ASR default yang akan digunakan semua basis pengetahuan baru yang dibuat. Gunakan model ini untuk menerjemahkan suara ke teks yang sesuai.',
      rerankModel: 'Model Rerank',
      rerankModelTip: `Model rerank default digunakan untuk mererank potongan yang diambil oleh pertanyaan pengguna.`,
      ttsModel: 'Model TTS',
      ttsModelTip:
        'Model TTS default akan digunakan untuk menghasilkan ucapan selama percakapan atas permintaan.',
      workspace: 'Ruang Kerja',
      upgrade: 'Tingkatkan',
      addLlmTitle: 'Tambahkan LLM',
      modelName: 'Nama Model',
      modelID: 'ID Model',
      modelUid: 'UID Model',
      modelNameMessage: 'Silakan masukkan nama model Anda!',
      modelType: 'Jenis Model',
      modelTypeMessage: 'Silakan masukkan jenis model Anda!',
      addLlmBaseUrl: 'Base url',
      baseUrlNameMessage: 'Silakan masukkan base url Anda!',
      vision: 'Apakah mendukung Vision?',
      ollamaLink: 'Cara mengintegrasikan {{name}}',
      FishAudioLink: 'Cara menggunakan FishAudio',
      TencentCloudLink: 'Cara menggunakan TencentCloud ASR',
      volcModelNameMessage: 'Silakan masukkan nama model Anda!',
      addEndpointID: 'EndpointID dari model',
      endpointIDMessage: 'Silakan masukkan EndpointID dari model',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Silakan masukkan ARK_API_KEY Anda',
      bedrockModelNameMessage: 'Silakan masukkan nama model Anda!',
      addBedrockEngineAK: 'ACCESS KEY',
      bedrockAKMessage: 'Silakan masukkan ACCESS KEY Anda',
      addBedrockSK: 'SECRET KEY',
      bedrockSKMessage: 'Silakan masukkan SECRET KEY Anda',
      bedrockRegion: 'Wilayah AWS',
      bedrockRegionMessage: 'Silakan pilih!',
      'us-east-1': 'US East (N. Virginia)',
      'us-west-2': 'US West (Oregon)',
      'ap-southeast-1': 'Asia Pacific (Singapore)',
      'ap-northeast-1': 'Asia Pacific (Tokyo)',
      'eu-central-1': 'Europe (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (US-West)',
      'ap-southeast-2': 'Asia Pacific (Sydney)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Silakan masukkan Secret ID Anda',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Silakan masukkan Secret Key Anda',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Silakan masukkan Secret ID Anda',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Silakan masukkan Secret Key Anda',
      SparkModelNameMessage: 'Silakan pilih model Spark',
      addSparkAPIPassword: 'Spark APIPassword',
      SparkAPIPasswordMessage: 'silakan masukkan APIPassword Anda',
      addSparkAPPID: 'Spark APPID',
      SparkAPPIDMessage: 'silakan masukkan APPID Anda',
      addSparkAPISecret: 'Spark APISecret',
      SparkAPISecretMessage: 'silakan masukkan APISecret Anda',
      addSparkAPIKey: 'Spark APIKey',
      SparkAPIKeyMessage: 'silakan masukkan APIKey Anda',
      yiyanModelNameMessage: 'Silakan masukkan nama model',
      addyiyanAK: 'yiyan API KEY',
      yiyanAKMessage: 'Silakan masukkan API KEY Anda',
      addyiyanSK: 'yiyan Secret KEY',
      yiyanSKMessage: 'Silakan masukkan Secret KEY Anda',
      FishAudioModelNameMessage: 'Silakan beri nama model sintesis ucapan Anda',
      addFishAudioAK: 'Fish Audio API KEY',
      addFishAudioAKMessage: 'Silakan masukkan API KEY Anda',
      addFishAudioRefID: 'FishAudio Refrence ID',
      addFishAudioRefIDMessage:
        'Silakan masukkan Reference ID (biarkan kosong untuk menggunakan model default).',
      GoogleModelIDMessage: 'Silakan masukkan ID model Anda!',
      addGoogleProjectID: 'Project ID',
      GoogleProjectIDMessage: 'Silakan masukkan Project ID Anda',
      addGoogleServiceAccountKey:
        'Kunci Akun Layanan (Biarkan kosong jika Anda menggunakan Kredensial Default Aplikasi)',
      GoogleServiceAccountKeyMessage:
        'Silakan masukkan Kunci Akun Layanan Google Cloud dalam format base64',
      addGoogleRegion: 'Wilayah Google Cloud',
      GoogleRegionMessage: 'Silakan masukkan Wilayah Google Cloud',
      modelProvidersWarn:
        'Silakan tambahkan model embedding dan LLM di <b>Pengaturan > Penyedia Model</b> terlebih dahulu.',
      apiVersion: 'Versi API',
      apiVersionMessage: 'Silakan masukkan versi API',
    },
    message: {
      registered: 'Terdaftar!',
      logout: 'keluar',
      logged: 'masuk!',
      pleaseSelectChunk: 'Silakan pilih potongan!',
      modified: 'Dimodifikasi',
      created: 'Dibuat',
      deleted: 'Dihapus',
      renamed: 'Diubah nama',
      operated: 'Dioperasikan',
      updated: 'Diperbarui',
      uploaded: 'Diunggah',
      200: 'Server berhasil mengembalikan data yang diminta.',
      201: 'Membuat atau memodifikasi data berhasil.',
      202: 'Permintaan telah diantrekan di latar belakang (tugas asinkron).',
      204: 'Data berhasil dihapus.',
      400: 'Ada kesalahan dalam permintaan yang dikeluarkan, dan server tidak membuat atau memodifikasi data.',
      401: 'Pengguna tidak memiliki izin (token, nama pengguna, kata sandi salah).',
      403: 'Pengguna diizinkan, tetapi akses dilarang.',
      404: 'Permintaan dibuat untuk catatan yang tidak ada, dan server tidak melakukan operasi.',
      406: 'Format yang diminta tidak tersedia.',
      410: 'Sumber daya yang diminta telah dihapus secara permanen dan tidak akan tersedia lagi.',
      413: 'Ukuran total file yang diunggah sekaligus terlalu besar.',
      422: 'Saat membuat objek, terjadi kesalahan validasi.',
      500: 'Terjadi kesalahan server, silakan periksa server.',
      502: 'Kesalahan gateway.',
      503: 'Layanan tidak tersedia dan server sementara kelebihan beban atau sedang dalam pemeliharaan.',
      504: 'Waktu habis gateway.',
      requestError: 'Kesalahan permintaan',
      networkAnomalyDescription:
        'Ada kelainan dalam jaringan Anda dan Anda tidak dapat terhubung ke server.',
      networkAnomaly: 'anomali jaringan',
      hint: 'petunjuk',
    },
    fileManager: {
      name: 'Nama',
      uploadDate: 'Tanggal Unggah',
      knowledgeBase: 'Basis Pengetahuan',
      size: 'Ukuran',
      action: 'Aksi',
      addToKnowledge: 'Tautkan ke Basis Pengetahuan',
      pleaseSelect: 'Silakan pilih',
      newFolder: 'Folder Baru',
      file: 'File',
      uploadFile: 'Unggah File',
      directory: 'Direktori',
      uploadTitle: 'Klik atau seret file ke area ini untuk mengunggah',
      uploadDescription:
        'Dukungan untuk unggahan tunggal atau massal. Dilarang keras mengunggah data perusahaan atau file terlarang lainnya.',
      local: 'Unggahan lokal',
      s3: 'Unggahan S3',
      preview: 'Pratinjau',
      fileError: 'Kesalahan file',
      uploadLimit:
        'Ukuran file tidak boleh melebihi 10M, dan jumlah total file tidak boleh melebihi 128',
      destinationFolder: 'Folder tujuan',
    },
    flow: {
      cite: 'Kutip',
      citeTip: 'tipKutip',
      name: 'Nama',
      nameMessage: 'Silakan masukkan nama',
      description: 'Deskripsi',
      examples: 'Contoh',
      to: 'Ke',
      msg: 'Pesan',
      messagePlaceholder: 'pesan',
      messageMsg: 'Silakan masukkan pesan atau hapus bidang ini.',
      addField: 'Tambah bidang',
      addMessage: 'Tambah pesan',
      loop: 'Loop',
      loopTip:
        'Loop adalah batas atas jumlah loop dari komponen saat ini, ketika jumlah loop melebihi nilai loop, itu berarti komponen tidak dapat menyelesaikan tugas saat ini, silakan optimalkan agen kembali',
      yes: 'Ya',
      no: 'Tidak',
      key: 'Kunci',
      componentId: 'ID Komponen',
      add: 'Tambah',
      operation: 'operasi',
      run: 'Jalankan',
      save: 'Simpan',
      title: 'ID:',
      beginDescription: 'Ini adalah tempat aliran dimulai.',
      answerDescription: `Komponen yang berfungsi sebagai antarmuka antara manusia dan bot, menerima input pengguna dan menampilkan respons agen.`,
      retrievalDescription: `Komponen yang mengambil informasi dari basis pengetahuan yang ditentukan dan mengembalikan 'Respon kosong' jika tidak ada informasi yang ditemukan. Pastikan basis pengetahuan yang benar dipilih.`,
      generateDescription: `Komponen yang meminta LLM untuk menghasilkan respons. Pastikan prompt diatur dengan benar.`,
      categorizeDescription: `Komponen yang menggunakan LLM untuk mengklasifikasikan input pengguna ke dalam kategori yang telah ditentukan. Pastikan Anda menentukan nama, deskripsi, dan contoh untuk setiap kategori, bersama dengan komponen berikutnya yang sesuai.`,
      relevantDescription: `Komponen yang menggunakan LLM untuk menilai apakah output hulu relevan dengan kueri terbaru pengguna. Pastikan Anda menentukan komponen berikutnya untuk setiap hasil penilaian.`,
      rewriteQuestionDescription: `Komponen yang menyempurnakan kueri pengguna jika gagal mengambil informasi yang relevan dari basis pengetahuan. Ini mengulangi proses ini hingga batas loop yang telah ditentukan tercapai. Pastikan hulu adalah 'Relevan' dan hilir adalah 'Pengambilan'.`,
      messageDescription:
        "Komponen yang mengirimkan pesan statis. Jika beberapa pesan disediakan, itu secara acak memilih satu untuk dikirim. Pastikan hilirnya adalah 'Jawaban', komponen antarmuka.",
      keywordDescription: `Komponen yang mengekstrak kata kunci dari kueri pengguna, dengan Top N menentukan jumlah kata kunci yang akan diekstraksi.`,
      switchDescription: `Komponen yang mengevaluasi kondisi berdasarkan output dari komponen sebelumnya dan mengarahkan aliran eksekusi sesuai. Ini memungkinkan logika percabangan yang kompleks dengan mendefinisikan kasus dan menentukan tindakan untuk setiap kasus atau tindakan default jika tidak ada kondisi yang terpenuhi.`,
      wikipediaDescription: `Komponen ini digunakan untuk mendapatkan hasil pencarian dari wikipedia.org. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu Anda sesuaikan.`,
      promptText: `Silakan rangkum paragraf berikut. Berhati-hatilah dengan angka, jangan membuat hal-hal yang tidak ada. Paragraf sebagai berikut:
            {input}
      Di atas adalah konten yang perlu Anda rangkum.`,
      createGraph: 'Buat agen',
      createFromTemplates: 'Buat dari template',
      retrieval: 'Pengambilan',
      generate: 'Hasilkan',
      answer: 'Berinteraksi',
      categorize: 'Kategorikan',
      relevant: 'Relevan',
      rewriteQuestion: 'Tulis ulang',
      rewrite: 'Tulis ulang',
      begin: 'Mulai',
      message: 'Pesan',
      blank: 'Kosong',
      createFromNothing: 'Buat agen Anda dari awal',
      addItem: 'Tambah Item',
      addSubItem: 'Tambah Sub Item',
      nameRequiredMsg: 'Nama diperlukan',
      nameRepeatedMsg: 'Nama tidak boleh diulang',
      keywordExtract: 'Kata Kunci',
      keywordExtractDescription: `Komponen yang mengekstrak kata kunci dari kueri pengguna, dengan Top N menentukan jumlah kata kunci yang akan diekstraksi.`,
      baidu: 'Baidu',
      baiduDescription: `Komponen ini digunakan untuk mendapatkan hasil pencarian dari www.baidu.com. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu Anda sesuaikan.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Komponen yang mengambil hasil pencarian dari duckduckgo.com, dengan TopN menentukan jumlah hasil pencarian. Ini melengkapi basis pengetahuan yang ada.',
      channel: 'Saluran',
      channelTip: `Lakukan pencarian teks atau pencarian berita pada input komponen`,
      text: 'Teks',
      news: 'Berita',
      messageHistoryWindowSize: 'Ukuran jendela pesan',
      messageHistoryWindowSizeTip:
        'Ukuran jendela riwayat percakapan yang perlu dilihat oleh LLM. Semakin besar semakin baik. Tetapi berhati-hatilah dengan panjang konten maksimum dari LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      email: 'Email',
      emailTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://pubmed.ncbi.nlm.nih.gov/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu Anda sesuaikan. E-mail adalah bidang yang diperlukan.',
      arXiv: 'ArXiv',
      arXivTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://arxiv.org/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu Anda sesuaikan.',
      sortBy: 'Urutkan berdasarkan',
      submittedDate: 'Tanggal diajukan',
      lastUpdatedDate: 'Tanggal diperbarui terakhir',
      relevance: 'Relevansi',
      google: 'Google',
      googleTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://www.google.com/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N dan kunci API SerpApi menentukan jumlah hasil pencarian yang perlu Anda sesuaikan.',
      bing: 'Bing',
      bingTip:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://www.bing.com/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N dan Kunci Langganan Bing menentukan jumlah hasil pencarian yang perlu Anda sesuaikan.',
      apiKey: 'Kunci API',
      country: 'Negara',
      language: 'Bahasa',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Komponen ini digunakan untuk mendapatkan hasil pencarian dari https://scholar.google.com/. Biasanya, ini berfungsi sebagai pelengkap basis pengetahuan. Top N menentukan jumlah hasil pencarian yang perlu Anda sesuaikan.',
      yearLow: 'Tahun terendah',
      yearHigh: 'Tahun tertinggi',
      patents: 'Paten',
      data: 'Data',
      deepL: 'DeepL',
      deepLDescription:
        'Komponen ini digunakan untuk mendapatkan terjemahan dari https://www.deepl.com/. Biasanya, ini memberikan hasil terjemahan yang lebih khusus.',
      authKey: 'Kunci otorisasi',
      sourceLang: 'Bahasa sumber',
      targetLang: 'Bahasa target',
      gitHub: 'GitHub',
      githubDescription:
        'Komponen ini digunakan untuk mencari repositori dari https://github.com/. Top N menentukan jumlah hasil pencarian yang akan disesuaikan.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Komponen ini digunakan untuk mendapatkan terjemahan dari https://fanyi.baidu.com/. Biasanya, ini memberikan hasil terjemahan yang lebih khusus',
      appid: 'ID aplikasi',
      secretKey: 'Kunci rahasia',
      domain: 'Domain',
      transType: 'Jenis terjemahan',
      baiduSecretKeyOptions: {
        translate: 'Terjemahan umum',
        fieldtranslate: 'Terjemahan bidang',
      },
      baiduDomainOptions: {
        it: 'Teknologi informasi',
        finance: 'Keuangan dan ekonomi',
        machinery: 'Manufaktur mesin',
        senimed: 'Biomedis',
        novel: 'Sastra daring',
        academic: 'Makalah akademik',
        aerospace: 'Dirgantara',
        wiki: 'Humaniora dan ilmu sosial',
        news: 'Berita dan informasi',
        law: 'Hukum dan peraturan',
        contract: 'Kontrak',
      },
      baiduSourceLangOptions: {
        auto: 'Deteksi otomatis',
        zh: 'Cina',
        en: 'Inggris',
        yue: 'Kanton',
        wyw: 'Cina Klasik',
        jp: 'Jepang',
        kor: 'Korea',
        fra: 'Prancis',
        spa: 'Spanyol',
        th: 'Thailand',
        ara: 'Arab',
        ru: 'Rusia',
        pt: 'Portugis',
        de: 'Jerman',
        it: 'Italia',
        el: 'Yunani',
        nl: 'Belanda',
        pl: 'Polandia',
        bul: 'Bulgaria',
        est: 'Estonia',
        dan: 'Denmark',
        fin: 'Finlandia',
        cs: 'Ceko',
        rom: 'Rumania',
        slo: 'Slovenia',
        swe: 'Swedia',
        hu: 'Hungaria',
        cht: 'Cina Tradisional',
        vie: 'Vietnam',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'Komponen ini digunakan untuk mendapatkan informasi terkait cuaca dari https://www.qweather.com/. Anda dapat mendapatkan cuaca, indeks, kualitas udara.',
      lang: 'Bahasa',
      type: 'Jenis',
      webApiKey: 'Kunci API Web',
      userType: 'Jenis pengguna',
      timePeriod: 'Periode waktu',
      qWeatherLangOptions: {
        zh: 'Cina Sederhana',
        'zh-hant': 'Cina Tradisional',
        en: 'Inggris',
        de: 'Jerman',
        es: 'Spanyol',
        fr: 'Prancis',
        it: 'Italia',
        ja: 'Jepang',
        ko: 'Korea',
        ru: 'Rusia',
        hi: 'Hindi',
        th: 'Thailand',
        ar: 'Arab',
        pt: 'Portugis',
        bn: 'Bengali',
        ms: 'Melayu',
        nl: 'Belanda',
        el: 'Yunani',
        la: 'Latin',
        sv: 'Swedia',
        id: 'Indonesia',
        pl: 'Polandia',
        tr: 'Turki',
        cs: 'Ceko',
        et: 'Estonia',
        vi: 'Vietnam',
        fil: 'Filipina',
        fi: 'Finlandia',
        he: 'Ibrani',
        is: 'Islandia',
        nb: 'Norwegia',
      },
      qWeatherTypeOptions: {
        weather: 'Prakiraan cuaca',
        indices: 'Indeks kehidupan cuaca',
        airquality: 'Kualitas udara',
      },
      qWeatherUserTypeOptions: {
        free: 'Pelanggan gratis',
        paid: 'Pelanggan berbayar',
      },
      qWeatherTimePeriodOptions: {
        now: 'Sekarang',
        '3d': '3 hari',
        '7d': '7 hari',
        '10d': '10 hari',
        '15d': '12 hari',
        '30d': '30 hari',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'Komponen ini menanyakan hasil dari database relasional yang sesuai melalui pernyataan SQL. Mendukung MySQL, PostgreSQL, MariaDB.',
      dbType: 'Jenis Database',
      database: 'Database',
      username: 'Nama Pengguna',
      host: 'Host',
      port: 'Port',
      password: 'Kata Sandi',
      switch: 'Sakelar',
      logicalOperator: 'Operator logis',
      switchOperatorOptions: {
        equal: 'sama dengan',
        notEqual: 'tidak sama dengan',
        gt: 'Lebih besar dari',
        ge: 'Lebih besar atau sama dengan',
        lt: 'Kurang dari',
        le: 'Kurang atau sama dengan',
        contains: 'Mengandung',
        notContains: 'Tidak mengandung',
        startWith: 'Mulai dengan',
        endWith: 'Berakhir dengan',
        empty: 'Kosong',
        notEmpty: 'Tidak kosong',
      },
      switchLogicOperatorOptions: {
        and: 'Dan',
        or: 'Atau',
      },
      operator: 'Operator',
      value: 'Nilai',
      useTemplate: 'Gunakan template ini',
      wenCai: 'WenCai',
      queryType: 'Jenis kueri',
      wenCaiDescription:
        'Komponen ini dapat digunakan untuk mendapatkan informasi di berbagai bidang keuangan, termasuk tetapi tidak terbatas pada saham, dana, dll...',
      wenCaiQueryTypeOptions: {
        stock: 'saham',
        zhishu: 'indeks',
        fund: 'dana',
        hkstock: 'Saham Hong Kong',
        usstock: 'Pasar saham AS',
        threeboard: 'Pasar OTC Baru',
        conbond: 'Obligasi Konversi',
        insurance: 'asuransi',
        futures: 'futures',
        lccp: 'Pembiayaan',
        foreign_exchange: 'Mata uang asing',
      },
      akShare: 'AkShare',
      akShareDescription:
        'Komponen ini dapat digunakan untuk mendapatkan ringkasan berita keuangan dari situs web keuangan utama, membantu penelitian industri dan kuantitatif.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'Komponen ini menanyakan informasi tentang perusahaan berdasarkan simbol ticker yang diberikan.',
      info: 'Info',
      history: 'Sejarah',
      financials: 'Keuangan',
      balanceSheet: 'Neraca',
      cashFlowStatement: 'Laporan Arus Kas',
      jin10: 'Jin10',
      jin10Description:
        'Komponen ini dapat digunakan untuk mengakses informasi di sektor keuangan dari Platform Terbuka Jin10, termasuk berita cepat, kalender, kutipan, referensi.',
      flashType: 'Jenis kilat',
      filter: 'Filter',
      contain: 'Mengandung',
      calendarType: 'Jenis kalender',
      calendarDatashape: 'Bentuk data kalender',
      symbolsDatatype: 'Jenis data simbol',
      symbolsType: 'Jenis simbol',
      jin10TypeOptions: {
        flash: 'Berita Cepat',
        calendar: 'Kalender',
        symbols: 'kutipan',
        news: 'referensi',
      },
      jin10FlashTypeOptions: {
        '1': 'Berita Pasar',
        '2': 'Berita Futures',
        '3': 'Berita AS-Hong Kong',
        '4': 'Berita Saham A',
        '5': 'Berita Komoditas & Forex',
      },
      jin10CalendarTypeOptions: {
        cj: 'Kalender Data Makroekonomi',
        qh: 'Kalender Futures',
        hk: 'Kalender Pasar Saham Hong Kong',
        us: 'Kalender Pasar Saham AS',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Data',
        event: 'Acara',
        holiday: 'Liburan',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Kutipan Komoditas',
        FOREX: 'Kutipan Forex',
        FUTURE: 'Kutipan Pasar Internasional',
        CRYPTO: 'Kutipan Cryptocurrency',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Daftar Komoditas',
        quotes: 'Kutipan Pasar Terbaru',
      },
      concentrator: 'Konsentrator',
      concentratorDescription:
        'Komponen yang menerima output dari komponen hulu dan meneruskannya sebagai input ke komponen hilir.',
      tuShare: 'TuShare',
      tuShareDescription:
        'Komponen ini dapat digunakan untuk mendapatkan ringkasan berita keuangan dari situs web keuangan utama, membantu penelitian industri dan kuantitatif.',
      tuShareSrcOptions: {
        sina: 'Sina',
        wallstreetcn: 'wallstreetcn',
        '10jqka': 'Flush langsung',
        eastmoney: 'Eastmoney',
        yuncaijing: 'YUNCAIJING',
        fenghuang: 'FENGHUANG',
        jinrongjie: 'JRJ',
      },
      token: 'Token',
      src: 'Sumber',
      startDate: 'Tanggal mulai',
      endDate: 'Tanggal akhir',
      keyword: 'Kata kunci',
      note: 'Catatan',
      noteDescription: 'Catatan',
      notePlaceholder: 'Silakan masukkan catatan',
    },
    footer: {
      profile: 'Semua hak dilindungi @ React',
    },
    layout: {
      file: 'file',
      knowledge: 'pengetahuan',
      chat: 'obrolan',
    },
  },
};
