export default {
  translation: {
    common: {
      delete: '削除',
      deleteModalTitle: 'この項目を削除してよろしいですか？',
      ok: 'はい',
      cancel: 'いいえ',
      total: 'トータル',
      rename: 'リネーム',
      name: '名前',
      save: '保存',
      namePlaceholder: '名前を入力してください',
      next: '次',
      create: '作成',
      edit: '編集',
      upload: 'アップロード',
      english: '英語',
      chinese: '中国語（簡体字）',
      traditionalChinese: '中国語（繁体字）',
      language: '言語',
      languageMessage: 'あなたの言語を入力してください！',
      languagePlaceholder: 'あなたの言語を選択してください',
      copy: 'コピー',
      copied: 'コピー済み',
      comingSoon: '近日公開',
      download: 'ダウンロード',
      close: '閉じる',
      preview: 'プレビュー',
      move: '移動',
      warn: '警告',
      action: 'アクション',
      s: '秒',
      pleaseSelect: '選択してください',
      pleaseInput: '入力してください',
      submit: '送信',
      japanese: '日本語',
    },
    login: {
      login: 'ログイン',
      signUp: 'サインアップ',
      loginDescription: 'お帰りなさい！またお会いできて嬉しいです。',
      registerDescription: 'ご登録ありがとうございます！',
      emailLabel: 'メールアドレス',
      emailPlaceholder: 'メールアドレスを入力してください',
      passwordLabel: 'パスワード',
      passwordPlaceholder: 'パスワードを入力してください',
      rememberMe: 'ログイン状態を保持する',
      signInTip: 'アカウントをお持ちでないですか？',
      signUpTip: 'すでにアカウントをお持ちですか？',
      nicknameLabel: 'ニックネーム',
      nicknamePlaceholder: 'ニックネームを入力してください',
      register: 'アカウント作成',
      continue: '続行',
      title: 'スマートアシスタントの構築を開始しましょう。',
      description:
        '無料でサインアップして、最先端のRAGテクノロジーを探索してください。ナレッジベースやAIを作成して、ビジネスを強化しましょう。',
      review: '500件以上のレビューより',
    },
    header: {
      knowledgeBase: 'ナレッジベース',
      chat: 'チャット',
      register: '登録',
      signin: 'ログイン',
      home: 'ホーム',
      setting: 'ユーザー設定',
      logout: 'ログアウト',
      fileManager: 'ファイル管理',
      flow: 'エージェント',
      search: '検索',
    },
    knowledgeList: {
      welcome: 'お帰りなさい',
      description: '今日はどのナレッジベースを使用しますか？',
      createKnowledgeBase: 'ナレッジベースを作成',
      name: '名前',
      namePlaceholder: '名前を入力してください',
      doc: 'ドキュメント',
      searchKnowledgePlaceholder: '検索',
      noMoreData: 'これですべてです。それ以上はありません。',
    },
    knowledgeDetails: {
      dataset: 'データセット',
      testing: '検索テスト',
      files: 'ファイル',
      configuration: '設定',
      name: '名前',
      namePlaceholder: '名前を入力してください',
      doc: 'ドキュメント',
      datasetDescription: '😉 パースが成功すると、質問と回答が可能になります。',
      addFile: 'ファイルを追加',
      searchFiles: 'ファイルを検索',
      localFiles: 'ローカルファイル',
      emptyFiles: '空のファイルを作成',
      webCrawl: 'ウェブクロール',
      chunkNumber: 'チャンク数',
      uploadDate: 'アップロード日',
      chunkMethod: 'チャンク方法',
      enabled: '有効',
      disabled: '無効',
      action: 'アクション',
      parsingStatus: 'パースステータス',
      processBeginAt: 'プロセス開始時刻',
      processDuration: '処理時間',
      progressMsg: '進行状況メッセージ',
      testingDescription:
        '検索テストを実施して、RAGFlowがLLM向けに意図した内容を復元できるかを確認します。',
      similarityThreshold: '類似度しきい値',
      similarityThresholdTip:
        'RAGFlowは、ユーザークエリとチャンク間の類似度スコアがこのしきい値を下回る場合、そのチャンクを結果から除外します。',
      vectorSimilarityWeight: 'ベクトル類似度の重み',
      vectorSimilarityWeightTip:
        'ベクトルコサイン類似度と併用する際のキーワード類似度の重みを設定します。2つの重みの合計は1.0でなければなりません。',
      testText: 'テストテキスト',
      testTextPlaceholder: '質問を入力してください',
      testingLabel: 'テスト',
      similarity: 'ハイブリッド類似度',
      termSimilarity: '用語類似度',
      vectorSimilarity: 'ベクトル類似度',
      hits: 'ヒット',
      view: '表示',
      filesSelected: '選択されたファイル',
      upload: 'アップロード',
      run: '解析する',
      runningStatus0: '未解決',
      runningStatus1: 'パース中',
      runningStatus2: 'キャンセル',
      runningStatus3: '成功',
      runningStatus4: '失敗',
      pageRanges: 'ページ範囲',
      pageRangesTip:
        '解析されるページの範囲を設定します。この範囲外のページは処理されません。',
      fromPlaceholder: '開始',
      fromMessage: '開始ページ番号が不足しています',
      toPlaceholder: '終了',
      toMessage: '終了ページ番号が不足しています（除外）',
      layoutRecognize: 'レイアウト認識',
      layoutRecognizeTip:
        'レイアウト分析のためにビジュアルモデルを使用し、文書の構造を理解しやすくします。',
      taskPageSize: 'タスクページサイズ',
      taskPageSizeMessage: 'タスクページサイズを入力してください',
      taskPageSizeTip: `レイアウト認識中、PDFファイルはチャンクに分割され、処理速度を向上させるために並列処理されます。`,
      addPage: 'ページを追加',
      greaterThan: '現在の値は終了ページより大きくなければなりません',
      greaterThanPrevious:
        '現在の値は前の終了ページより大きくなければなりません',
      selectFiles: 'ファイルを選択',
      changeSpecificCategory: '特定のカテゴリを変更',
      uploadTitle: 'クリックまたはドラッグしてファイルをアップロード',
      uploadDescription:
        '単一または一括アップロードに対応。企業データや禁止されたファイルのアップロードは禁止されています。',
      chunk: 'チャンク',
      bulk: '一括',
      cancel: 'キャンセル',
      rerankModel: 'リランキングモデル',
      rerankPlaceholder: '選択してください',
      rerankTip: `リランキングモデルを選択しない場合、RAGFlowはデフォルトの重み付きベクトルコサイン類似度を使用します。`,
      topK: 'トップK',
      topKTip: `Kチャンクがリランキングモデルに供給されます。`,
      delimiter: `区切り文字`,
      delimiterTip: '複数文字の区切り文字をサポートしています。',
      html4excel: 'ExcelをHTMLに変換',
      html4excelTip: `有効にすると、スプレッドシートはHTMLテーブルとして解析されます。それ以外の場合、キーと値のペアとして解析されます。`,
      autoKeywords: '自動キーワード',
      autoKeywordsTip: `各チャンクの上位Nキーワードを抽出してランキングを向上させます。`,
      autoQuestions: '自動質問',
      autoQuestionsTip: `各チャンクの上位N質問を抽出してランキングを向上させます。`,
    },
    knowledgeConfiguration: {
      titleDescription:
        'ナレッジベースの設定、特にチャンク方法をここで更新してください。',
      name: 'ナレッジベース名',
      photo: 'ナレッジベース写真',
      description: '説明',
      language: '言語',
      languageMessage: '言語を入力してください',
      languagePlaceholder: '言語を選択してください',
      permissions: '権限',
      embeddingModel: '埋め込みモデル',
      chunkTokenNumber: 'チャンクトークン数',
      chunkTokenNumberMessage: 'チャンクトークン数は必須です',
      embeddingModelTip:
        'チャンクを埋め込みに変換するモデルです。一度チャンクが作成されると変更できません。',
      permissionsTip:
        '「チーム」に設定すると、全てのチームメンバーがナレッジベースを管理できます。',
      chunkTokenNumberTip:
        'チャンクのトークンしきい値を設定します。このしきい値を下回る段落は、次の段落と結合され、しきい値を超えた時点でチャンクが作成されます。',
      chunkMethod: 'チャンク方法',
      chunkMethodTip: '詳細は右側の説明をご覧ください。',
      upload: 'アップロード',
      english: '英語',
      chinese: '中国語',
      embeddingModelPlaceholder: '埋め込みモデルを選択してください',
      chunkMethodPlaceholder: 'チャンク方法を選択してください',
      save: '保存',
      me: '自分のみ',
      team: 'チーム',
      cancel: 'キャンセル',
      methodTitle: 'チャンク方法の説明',
      methodExamples: '例',
      methodExamplesDescription:
        '以下のスクリーンショットは明確な説明のために提供されています。',
      dialogueExamplesTitle: '会話の例',
      methodEmpty: 'ナレッジベースカテゴリの視覚的説明がここに表示されます',
      book: `<p>対応ファイル形式は<b>DOCX</b>, <b>PDF</b>, <b>TXT</b>です。</p><p>
      PDF形式の書籍では、解析時間を短縮するため、<i>ページ範囲</i>を設定してください。</p>`,
      laws: `<p>対応ファイル形式は<b>DOCX</b>, <b>PDF</b>, <b>TXT</b>です。</p><p>
      法律文書は厳格な書式に従っています。分割ポイントを識別するためにテキストの特徴を使用します。
      </p><p>
      'ARTICLE'に一致する粒度でチャンクが作成され、上位レベルのテキストがすべてチャンクに含まれます。
      </p>`,
      manual: `<p>対応するのは<b>PDF</b>のみです。</p><p>
      マニュアルは階層的なセクション構造を持つと仮定され、最下位のセクションタイトルを基にチャンク分割を行います。そのため、同じセクション内の図表は分割されませんが、大きなチャンクサイズになる可能性があります。
      </p>`,
      naive: `<p>対応ファイル形式は<b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>です。</p>
      <p>この方法では、'ナイーブ'な方法でファイルを分割します：</p>
      <p>
      <li>視覚認識モデルを使用してテキストを小さなセグメントに分割します。</li>
      <li>次に、隣接するセグメントを結合し、設定された'チャンクトークン数'のしきい値を超えるとチャンクが作成されます。</li></p>`,
      paper: `<p><b>PDF</b>形式のみ対応しています。</p><p>
      論文はセクション単位で分割されます（例：abstract, 1.1, 1.2）。</p><p>
      この方法により、LLMは論文を効果的に要約し、包括的で理解しやすい応答を提供できます。
      ただし、AI会話のコンテキストが増加し、計算コストが増加します。そのため、会話中は「<b>topN</b>」の値を小さくすることを検討してください。</p>`,
      presentation: `<p>対応ファイル形式は<b>PDF</b>, <b>PPTX</b>です。</p><p>
      スライドの各ページはチャンクとして扱われ、そのサムネイル画像が保存されます。</p><p>
      <i>このチャンク方法はすべてのPPTファイルに自動的に適用されるため、手動で指定する必要はありません。</i></p>`,
      qa: `
      <p>
      このチャンク方法は<b>EXCEL</b>および<b>CSV/TXT</b>ファイル形式をサポートします。
    </p>
    <li>
      <b>Excel</b>形式のファイルには、ヘッダーのない2つの
      列が必要です： 1つは質問の列でもう1つは回答の列です
      （質問列が先行）。複数のシートも可能です。
      
    </li>
    <li>
     <b>CSV/TXT</b>形式のファイルは、TABで区切られたUTF-8エンコードである必要があります。
    </li>
    <p>
      <i>
          上記のルールに従わないテキスト行は無視され、
          各Q&Aのペアは独立したチャンクとして扱われます。
      </i>
    </p>
      `,
      resume: `<p>対応ファイル形式は<b>DOCX</b>, <b>PDF</b>, <b>TXT</b>です。
      </p><p>
      さまざまな形式の履歴書を解析し、構造化データとして整理してリクルーターの候補者検索を支援します。
      </p>
      `,
      table: `<p>対応ファイル形式は<b>EXCEL</b>および<b>CSV/TXT</b>です。</p><p>
      いくつかの前提条件とヒントはこちらです：
      <ul>
    <li>CSVまたはTXTファイルの場合、列間の区切り文字は<b>TAB</b>である必要があります。</li>
    <li>最初の行は列ヘッダーである必要があります。</li>
    <li>列ヘッダーは、LLMの理解を助ける意味のある用語でなければなりません。
    同義語をスラッシュ<i>'/'</i>で並べて表示し、値を括弧で列挙するのは良い練習です。例：<i>'性別/ジェンダー（男性、女性）'</i></p>
    ヘッダーの例はこちらです：<ol>
        <li>供給者/ベンダー<b>'TAB'</b>色（黄色、青、茶色）<b>'TAB'</b>性別/ジェンダー（男性、女性）<b>'TAB'</b>サイズ（M、L、XL、XXL）</li>
        </ol>
        </p>
    </li>
    <li>テーブルの各行はチャンクとして扱われます。</li>
    </ul>`,
      picture: `
    <p>画像ファイルが対応しています。動画対応は近日公開予定です。</p><p>
    この方法ではOCRモデルを使用して画像からテキストを抽出します。
    </p><p>
    OCRモデルで抽出されたテキストが不十分と見なされた場合、指定された視覚LLMが画像の説明を提供します。
    </p>`,
      one: `
    <p>対応ファイル形式は<b>DOCX, EXCEL, PDF, TXT</b>です。
    </p><p>
    この方法では、ドキュメント全体を1つのチャンクとして扱います。
    </p><p>
    LLMがその量のコンテキスト長を処理できる場合に、ドキュメント全体を要約する必要があるときに適用されます。
    </p>`,
      knowledgeGraph: `<p>対応ファイル形式は<b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>です。
          
<p>このアプローチでは、ファイルを'ナイーブ'/'一般'メソッドを使用してチャンクに分割します。ドキュメントをセグメントに分割し、隣接するセグメントを結合してトークン数が'チャンクトークン数'で指定されたしきい値を超えるまで続け、その時点でチャンクが作成されます。</p>
<p>その後、チャンクはLLMに入力され、ナレッジグラフとマインドマップのエンティティと関係を抽出します。</p>
<p><b>エンティティタイプ</b>を設定することを忘れないでください。</p>`,
      useRaptor: 'RAPTORを使用して検索を強化',
      useRaptorTip:
        'ツリー構造化検索のための再帰的抽象処理（RAPTOR）については、詳細はhttps://huggingface.co/papers/2401.18059をご覧ください',
      prompt: 'プロンプト',
      promptTip: '要約に使用されるLLMプロンプト。',
      promptMessage: 'プロンプトは必須です',
      promptText: `以下の段落を要約してください。数字には注意し、事実を捏造しないでください。段落は以下の通りです：
      {cluster_content}
上記が要約する内容です。`,
      maxToken: '最大トークン数',
      maxTokenTip: '要約のための最大トークン数。',
      maxTokenMessage: '最大トークン数は必須です',
      threshold: 'しきい値',
      thresholdTip: 'しきい値が大きいほどクラスターは少なくなります。',
      thresholdMessage: 'しきい値は必須です',
      maxCluster: '最大クラスター数',
      maxClusterTip: '最大クラスター数。',
      maxClusterMessage: '最大クラスター数は必須です',
      randomSeed: 'ランダムシード',
      randomSeedMessage: 'ランダムシードは必須です',
      entityTypes: 'エンティティタイプ',
      pageRank: 'ページランク',
      pageRankTip: `これは関連性スコアを高めるために使用されます。すべての取得されたチャンクの関連性スコアにこの数値が加算されます。
特定のナレッジベースを最初に検索したい場合は、他のものよりも高いページランクスコアを設定してください。`,
    },
    chunk: {
      chunk: 'チャンク',
      bulk: '一括',
      selectAll: 'すべて選択',
      enabledSelected: '選択を有効化',
      disabledSelected: '選択を無効化',
      deleteSelected: '選択を削除',
      search: '検索',
      all: 'すべて',
      enabled: '有効',
      disabled: '無効',
      keyword: 'キーワード',
      function: '機能',
      chunkMessage: '値を入力してください！',
      full: '全文',
      ellipse: '省略',
      graph: 'ナレッジグラフ',
      mind: 'マインドマップ',
      question: '質問',
      questionTip: `質問が指定されている場合、チャンクの埋め込みはそれらに基づきます。`,
    },
    chat: {
      newConversation: '新しい会話',
      createAssistant: 'アシスタントを作成',
      assistantSetting: 'アシスタント設定',
      promptEngine: 'プロンプトエンジン',
      modelSetting: 'モデル設定',
      chat: 'チャット',
      newChat: '新しいチャット',
      send: '送信',
      sendPlaceholder: 'アシスタントにメッセージを送信...',
      chatConfiguration: 'チャット設定',
      chatConfigurationDescription:
        '特別なナレッジベースのために専用アシスタントを作成しましょう！ 💕',
      assistantName: 'アシスタント名',
      assistantNameMessage: 'アシスタント名は必須です',
      namePlaceholder: '例: 履歴書アシスタント',
      assistantAvatar: 'アシスタントのアバター',
      language: '言語',
      emptyResponse: '空の応答',
      emptyResponseTip: `ナレッジベースに該当する内容がない場合、この応答が使用されます。`,
      setAnOpener: 'オープニングメッセージを設定',
      setAnOpenerInitial: `こんにちは！ 私はあなたのアシスタントです。何をお手伝いしましょうか？`,
      setAnOpenerTip: 'お客様をどのように歓迎しますか？',
      knowledgeBases: 'ナレッジベース',
      knowledgeBasesMessage: '選択してください',
      knowledgeBasesTip: '関連付けるナレッジベースを選択してください。',
      system: 'システム',
      systemInitialValue: `あなたはインテリジェントなアシスタントです。質問に答えるためにナレッジベースの内容を要約してください。ナレッジベースのデータをリストし、詳細に答えてください。すべてのナレッジベースの内容が質問に関連しない場合、回答には「ナレッジベースにはお探しの回答が見つかりません！」という文を含める必要があります。回答はチャット履歴を考慮する必要があります。
      こちらがナレッジベースです：
      {knowledge}
      上記がナレッジベースです。`,
      systemMessage: '入力してください！',
      systemTip: 'LLMが質問に答える際に従う指示を設定します。',
      topN: 'トップN',
      topNTip: `類似度スコアがしきい値を超えるチャンクのうち、上位N件のみがLLMに供給されます。`,
      variable: '変数',
      variableTip: `ダイアログAPIを使用する場合、変数は異なる戦略でクライアントとチャットするのに役立ちます。
      変数はプロンプトの'システム'部分を埋めるために使用され、LLMにヒントを与えます。
      'ナレッジ'は取得されたチャンクで埋められる非常に特別な変数です。
      'システム'のすべての変数は中括弧で囲む必要があります。`,
      add: '追加',
      key: 'キー',
      optional: 'オプション',
      operation: '操作',
      model: 'モデル',
      modelTip: '大規模言語チャットモデル',
      modelMessage: '選択してください！',
      freedom: '自由度',
      improvise: '自由に',
      precise: '正確に',
      balance: 'バランス',
      freedomTip: `'正確に'は、LLMが慎重に質問に答えることを意味します。'自由に'は、LLMが多く話し、自由に答えることを望むことを意味します。'バランス'は、慎重さと自由さの間のバランスを取ることを意味します。`,
      temperature: '温度',
      temperatureMessage: '温度は必須です',
      temperatureTip:
        'このパラメータは、モデルによる予測のランダム性を制御します。温度が低いほど、モデルは回答に自信を持ち、温度が高いほど、より創造的で多様な回答を生成します。',
      topP: 'Top P',
      topPMessage: 'Top Pは必須です',
      topPTip:
        '"核サンプリング"とも呼ばれ、このパラメータは、サンプリングする単語の小さなセットを選択するためのしきい値を設定します。最も可能性の高い単語に焦点を当て、可能性の低い単語を切り捨てます。',
      presencePenalty: '存在ペナルティ',
      presencePenaltyMessage: '存在ペナルティは必須です',
      presencePenaltyTip:
        'これは、会話中に既に登場した単語にペナルティを課すことで、モデルが同じ情報を繰り返すのを防ぎます。',
      frequencyPenalty: '頻度ペナルティ',
      frequencyPenaltyMessage: '頻度ペナルティは必須です',
      frequencyPenaltyTip:
        '存在ペナルティと同様に、モデルが同じ単語を頻繁に繰り返す傾向を減少させます。',
      maxTokens: '最大トークン数',
      maxTokensMessage: '最大トークン数は必須です',
      maxTokensTip:
        'これは、モデルの出力の最大長を設定します。トークン（単語または単語の一部）の数で測定されます。',
      maxTokensInvalidMessage: '最大トークン数に有効な数値を入力してください。',
      maxTokensMinMessage: '最大トークン数は0以上でなければなりません。',
      quote: '引用を表示',
      quoteTip: '元のテキストの出典を表示しますか？',
      selfRag: 'Self-RAG',
      selfRagTip:
        '詳細は次を参照してください：https://huggingface.co/papers/2310.11511',
      overview: 'チャットID',
      pv: 'メッセージ数',
      uv: 'アクティブユーザー数',
      speed: 'トークン出力速度',
      tokens: '消費トークン数',
      round: 'セッションインタラクション数',
      thumbUp: '顧客満足度',
      preview: 'プレビュー',
      embedded: '埋め込み',
      serviceApiEndpoint: 'サービスAPIエンドポイント',
      apiKey: 'APIキー',
      apiReference: 'APIドキュメント',
      dateRange: '日付範囲：',
      backendServiceApi: 'APIサーバー',
      createNewKey: '新しいキーを作成',
      created: '作成日',
      action: 'アクション',
      embedModalTitle: 'ウェブサイトに埋め込む',
      comingSoon: '近日公開',
      fullScreenTitle: '全画面埋め込み',
      fullScreenDescription:
        '以下のiframeをウェブサイトの希望する場所に埋め込んでください',
      partialTitle: '部分埋め込み',
      extensionTitle: 'Chrome拡張機能',
      tokenError: 'まずAPIトークンを作成してください！',
      searching: '検索中...',
      parsing: '解析中',
      uploading: 'アップロード中',
      uploadFailed: 'アップロード失敗',
      regenerate: '再生成',
      read: 'コンテンツを読む',
      tts: 'テキスト読み上げ',
      ttsTip:
        '音声変換を使用して音声を再生するには、まず設定でTTS（音声変換モデル）を選択してください。',
      relatedQuestion: '関連質問',
      answerTitle: '回答',
      multiTurn: 'マルチターン最適化',
      multiTurnTip:
        'マルチラウンドの会話では、ナレッジベースへのクエリが最適化されます。大規模モデルが呼び出され、追加のトークンが消費されます。',
      howUseId: 'チャットIDの使い方？',
      description: 'アシスタントの説明',
    },
    setting: {
      profile: 'プロファイル',
      profileDescription: 'ここで写真と個人情報を更新してください。',
      maxTokens: '最大トークン数',
      maxTokensMessage: '最大トークン数は必須です',
      maxTokensTip:
        'これは、モデルの出力の最大長を設定します。トークン（単語または単語の一部）の数で測定されます。',
      maxTokensInvalidMessage: '有効な数値を入力してください。',
      maxTokensMinMessage: '最大トークン数は0以上でなければなりません。',
      password: 'パスワード',
      passwordDescription:
        'パスワードを変更するには、現在のパスワードを入力してください。',
      model: 'モデルプロバイダー',
      modelDescription: 'ここでモデルパラメータとAPIキーを設定します。',
      team: 'チーム',
      system: 'システム',
      logout: 'ログアウト',
      api: 'API',
      username: 'ユーザー名',
      usernameMessage: 'ユーザー名を入力してください！',
      photo: 'あなたの写真',
      photoDescription: 'これはプロフィールに表示されます。',
      colorSchema: 'カラースキーマ',
      colorSchemaMessage: 'カラースキーマを選択してください！',
      colorSchemaPlaceholder: 'カラースキーマを選択',
      bright: '明るい',
      dark: '暗い',
      timezone: 'タイムゾーン',
      timezoneMessage: 'タイムゾーンを入力してください！',
      timezonePlaceholder: 'タイムゾーンを選択',
      email: 'メールアドレス',
      emailDescription: '登録後、メールアドレスは変更できません。',
      currentPassword: '現在のパスワード',
      currentPasswordMessage: 'パスワードを入力してください！',
      newPassword: '新しいパスワード',
      newPasswordMessage: 'パスワードを入力してください！',
      newPasswordDescription:
        '新しいパスワードは8文字以上でなければなりません。',
      confirmPassword: '新しいパスワードの確認',
      confirmPasswordMessage: 'パスワードを確認してください！',
      confirmPasswordNonMatchMessage:
        '入力した新しいパスワードが一致しません！',
      cancel: 'キャンセル',
      addedModels: '追加されたモデル',
      modelsToBeAdded: '追加されるモデル',
      addTheModel: 'モデルを追加',
      apiKey: 'APIキー',
      apiKeyMessage:
        'APIキーを入力してください（ローカルにデプロイされたモデルの場合は無視してください）。',
      apiKeyTip:
        'APIキーは、対応するLLMサプライヤーに登録することで取得できます。',
      showMoreModels: 'さらにモデルを表示',
      baseUrl: 'ベースURL',
      baseUrlTip:
        'APIキーがOpenAIからのものであれば無視してください。他の中間プロバイダーはAPIキーと共にこのベースURLを提供します。',
      modify: '変更',
      systemModelSettings: 'システムモデル設定',
      chatModel: 'チャットモデル',
      chatModelTip:
        '新しく作成されたナレッジベースが使用するデフォルトのチャットLLM。',
      embeddingModel: '埋め込みモデル',
      embeddingModelTip:
        '新しく作成されたナレッジベースが使用するデフォルトの埋め込みモデル。',
      img2txtModel: '画像からテキストへのモデル',
      img2txtModelTip:
        '新しく作成されたナレッジベースが使用するデフォルトのマルチモジュールモデル。画像やビデオを説明できます。',
      sequence2txtModel: 'シーケンスからテキストへのモデル',
      sequence2txtModelTip:
        '新しく作成されたナレッジベースが使用するデフォルトのASRモデル。音声を対応するテキストに変換するために使用します。',
      rerankModel: '再ランクモデル',
      rerankModelTip: `ユーザーの質問によって取得されたチャンクを再ランク付けするために使用されるデフォルトの再ランクモデル。`,
      ttsModel: 'TTSモデル',
      ttsModelTip:
        '会話中にリクエストに応じて音声を生成するために使用されるデフォルトのTTSモデル。',
      workspace: 'ワークスペース',
      upgrade: 'アップグレード',
      addLlmTitle: 'LLMを追加',
      modelName: 'モデル名',
      modelID: 'モデルID',
      modelUid: 'モデルUID',
      modelNameMessage: 'モデル名を入力してください！',
      modelType: 'モデルタイプ',
      modelTypeMessage: 'モデルタイプを入力してください！',
      addLlmBaseUrl: 'ベースURL',
      baseUrlNameMessage: 'ベースURLを入力してください！',
      vision: 'ビジョンをサポートしていますか？',
      ollamaLink: '{{name}}を統合する方法',
      FishAudioLink: 'FishAudioの使用方法',
      TencentCloudLink: 'TencentCloud ASRの使用方法',
      volcModelNameMessage: 'モデル名を入力してください！',
      addEndpointID: 'モデルのエンドポイントID',
      endpointIDMessage: 'モデルのエンドポイントIDを入力してください',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'ARK_API_KEYを入力してください',
      bedrockModelNameMessage: 'モデル名を入力してください！',
      addBedrockEngineAK: 'アクセスキー',
      bedrockAKMessage: 'アクセスキーを入力してください',
      addBedrockSK: 'シークレットキー',
      bedrockSKMessage: 'シークレットキーを入力してください',
      bedrockRegion: 'AWSリージョン',
      bedrockRegionMessage: '選択してください！',
      'us-east-1': '米国東部（バージニア北部）',
      'us-west-2': '米国西部（オレゴン）',
      'ap-southeast-1': 'アジア太平洋（シンガポール）',
      'ap-northeast-1': 'アジア太平洋（東京）',
      'eu-central-1': 'ヨーロッパ（フランクフルト）',
      'us-gov-west-1': 'AWS GovCloud（米国西部）',
      'ap-southeast-2': 'アジア太平洋（シドニー）',
      addHunyuanSID: 'HunyuanシークレットID',
      HunyuanSIDMessage: 'シークレットIDを入力してください',
      addHunyuanSK: 'Hunyuanシークレットキー',
      HunyuanSKMessage: 'シークレットキーを入力してください',
      addTencentCloudSID: 'TencentCloudシークレットID',
      TencentCloudSIDMessage: 'シークレットIDを入力してください',
      addTencentCloudSK: 'TencentCloudシークレットキー',
      TencentCloudSKMessage: 'シークレットキーを入力してください',
      SparkModelNameMessage: 'Sparkモデルを選択してください',
      addSparkAPIPassword: 'Spark APIパスワード',
      SparkAPIPasswordMessage: 'APIパスワードを入力してください',
      addSparkAPPID: 'Spark APPID',
      SparkAPPIDMessage: 'APPIDを入力してください',
      addSparkAPISecret: 'Spark APIシークレット',
      SparkAPISecretMessage: 'APIシークレットを入力してください',
      addSparkAPIKey: 'Spark APIキー',
      SparkAPIKeyMessage: 'APIキーを入力してください',
      yiyanModelNameMessage: 'モデル名を入力してください',
      addyiyanAK: 'yiyan APIキー',
      yiyanAKMessage: 'APIキーを入力してください',
      addyiyanSK: 'yiyanシークレットキー',
      yiyanSKMessage: 'シークレットキーを入力してください',
      FishAudioModelNameMessage: '音声合成モデルに名前を付けてください',
      addFishAudioAK: 'Fish Audio APIキー',
      addFishAudioAKMessage: 'APIキーを入力してください',
      addFishAudioRefID: 'FishAudio参照ID',
      addFishAudioRefIDMessage:
        '参照IDを入力してください（デフォルトモデルを使用する場合は空白のままにしてください）。',
      GoogleModelIDMessage: 'モデルIDを入力してください！',
      addGoogleProjectID: 'プロジェクトID',
      GoogleProjectIDMessage: 'プロジェクトIDを入力してください',
      addGoogleServiceAccountKey:
        'サービスアカウントキー（アプリケーションデフォルト認証情報を使用する場合は空白のままにしてください）',
      GoogleServiceAccountKeyMessage:
        'Google Cloudサービスアカウントキーをbase64形式で入力してください',
      addGoogleRegion: 'Google Cloudリージョン',
      GoogleRegionMessage: 'Google Cloudリージョンを入力してください',
      modelProvidersWarn: `まず<b>設定 > モデルプロバイダー</b>で埋め込みモデルとLLMの両方を追加してください。その後、「システムモデル設定」で設定します。`,
      apiVersion: 'APIバージョン',
      apiVersionMessage: 'APIバージョンを入力してください',
      add: '追加',
      updateDate: '更新日',
      role: '役割',
      invite: '招待',
      agree: '承認',
      refuse: '拒否',
      teamMembers: 'チームメンバー',
      joinedTeams: '参加したチーム',
      sureDelete: 'このメンバーを削除してもよろしいですか？',
      quit: '退出',
      sureQuit: '参加したチームから退出してもよろしいですか？',
    },
    message: {
      registered: '登録完了！',
      logout: 'ログアウト',
      logged: 'ログイン完了！',
      pleaseSelectChunk: 'チャンクを選択してください！',
      modified: '変更されました',
      created: '作成されました',
      deleted: '削除されました',
      renamed: '名前が変更されました',
      operated: '操作が完了しました',
      updated: '更新されました',
      uploaded: 'アップロードされました',
      200: 'サーバーはリクエストされたデータを正常に返しました。',
      201: 'データが正常に作成または変更されました。',
      202: 'リクエストがバックグラウンドでキューに入れられました（非同期タスク）。',
      204: 'データが正常に削除されました。',
      400: '発行されたリクエストにエラーがあり、サーバーはデータを作成または変更しませんでした。',
      401: 'ユーザーには権限がありません（トークン、ユーザー名、またはパスワードが間違っています）。',
      403: 'ユーザーは認証されていますが、アクセスは禁止されています。',
      404: 'リクエストされたレコードが存在せず、サーバーは操作を実行しませんでした。',
      406: 'リクエストされた形式は利用できません。',
      410: 'リクエストされたリソースは永久に削除され、再び利用できません。',
      413: '一度にアップロードされたファイルの合計サイズが大きすぎます。',
      422: 'オブジェクトを作成するときに検証エラーが発生しました。',
      500: 'サーバーエラーが発生しました。サーバーを確認してください。',
      502: 'ゲートウェイエラー。',
      503: 'サービスが利用できず、サーバーが一時的に過負荷状態かメンテナンス中です。',
      504: 'ゲートウェイタイムアウト。',
      requestError: 'リクエストエラー',
      networkAnomalyDescription:
        'ネットワークに異常があり、サーバーに接続できません。',
      networkAnomaly: 'ネットワーク異常',
      hint: 'ヒント',
    },
    fileManager: {
      name: '名前',
      uploadDate: 'アップロード日',
      knowledgeBase: 'ナレッジベース',
      size: 'サイズ',
      action: 'アクション',
      addToKnowledge: 'ナレッジベースにリンク',
      pleaseSelect: '選択してください',
      newFolder: '新しいフォルダ',
      file: 'ファイル',
      uploadFile: 'ファイルをアップロード',
      directory: 'ディレクトリ',
      uploadTitle: 'クリックまたはドラッグしてファイルをアップロード',
      uploadDescription:
        '単一または一括アップロードをサポートします。企業データや禁止されたファイルのアップロードは禁止されています。',
      local: 'ローカルアップロード',
      s3: 'S3アップロード',
      preview: 'プレビュー',
      fileError: 'ファイルエラー',
      uploadLimit:
        'ファイルサイズは10MBを超えることはできず、同時にアップロードできるファイル数は128を超えることはできません。',
      destinationFolder: '保存先フォルダ',
    },
    flow: {
      cite: '引用',
      citeTip: '引用に関するヒント',
      name: '名前',
      nameMessage: '名前を入力してください',
      description: '説明',
      examples: '例',
      to: '宛先',
      msg: 'メッセージ',
      messagePlaceholder: 'メッセージを入力',
      messageMsg: 'メッセージを入力するか、このフィールドを削除してください。',
      addField: 'フィールドを追加',
      addMessage: 'メッセージを追加',
      loop: 'ループ',
      loopTip:
        'ループは現在のコンポーネントのループ回数の上限です。ループの値を超えると、コンポーネントが現在のタスクを完了できないことを意味します。エージェントを再最適化してください。',
      yes: 'はい',
      no: 'いいえ',
      key: 'キー',
      componentId: 'コンポーネントID',
      add: '追加',
      operation: '操作',
      run: '実行',
      save: '保存',
      title: 'ID:',
      beginDescription: 'ここからフローが始まります。',
      answerDescription: `人間とボットの間のインターフェースとして機能し、ユーザーの入力を受け取り、エージェントの応答を表示するコンポーネント。`,
      retrievalDescription: `指定されたナレッジベースから情報を取得し、情報が見つからない場合は「空の応答」を返すコンポーネント。正しいナレッジベースが選択されていることを確認してください。`,
      generateDescription: `LLMに応答を生成させるコンポーネント。プロンプトが正しく設定されていることを確認してください。`,
      categorizeDescription: `LLMを使用してユーザーの入力を事前定義されたカテゴリに分類するコンポーネント。各カテゴリの名前、説明、例、および対応する次のコンポーネントを指定してください。`,
      relevantDescription: `LLMを使用して、上流の出力がユーザーの最新のクエリに関連しているかどうかを評価するコンポーネント。各判定結果に対して次のコンポーネントを指定してください。`,
      rewriteQuestionDescription: `ナレッジベースから関連情報を取得できなかった場合にユーザーのクエリを修正するコンポーネント。定義されたループの上限に達するまでこのプロセスを繰り返します。上流が「Relevant」、下流が「Retrieval」であることを確認してください。`,
      messageDescription:
        '静的メッセージを送信するコンポーネント。複数のメッセージが提供されている場合は、その中からランダムに1つを選択して送信します。下流がインターフェースコンポーネント「Answer」であることを確認してください。',
      keywordDescription: `ユーザーの入力からトップNの検索結果を取得するコンポーネント。使用前にTopNの値が適切に設定されていることを確認してください。`,
      switchDescription: `前のコンポーネントの出力に基づいて条件を評価し、それに応じて実行の流れを指示するコンポーネント。ケースを定義し、各ケースのアクションまたは条件が満たされない場合のデフォルトアクションを指定することで、複雑な分岐ロジックを可能にします。`,
      wikipediaDescription: `wikipedia.orgから検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。`,
      promptText: `以下の段落を要約してください。数字に注意し、事実を捏造しないでください。段落は次の通りです：
        {input}
  上記は要約する必要がある内容です。`,
      createGraph: 'エージェントを作成',
      createFromTemplates: 'テンプレートから作成',
      retrieval: '検索',
      generate: '生成',
      answer: 'インタラクション',
      categorize: '分類',
      relevant: '関連性',
      rewriteQuestion: '質問を再生成',
      rewrite: '再生成',
      begin: '開始',
      message: 'メッセージ',
      blank: '空白',
      createFromNothing: '最初からエージェントを作成',
      addItem: '項目を追加',
      addSubItem: 'サブ項目を追加',
      nameRequiredMsg: '名前は必須です',
      nameRepeatedMsg: '名前が重複しています',
      keywordExtract: 'キーワード抽出',
      keywordExtractDescription: `ユーザーのクエリからキーワードを抽出するコンポーネントで、Top Nは抽出するキーワードの数を指定します。`,
      baidu: 'Baidu',
      baiduDescription: `baidu.comから検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'duckduckgo.comから検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。',
      channel: 'チャンネル',
      channelTip: `コンポーネントの入力に対してテキスト検索またはニュース検索を実行します`,
      text: 'テキスト',
      news: 'ニュース',
      messageHistoryWindowSize: 'メッセージウィンドウサイズ',
      messageHistoryWindowSizeTip:
        'LLMが参照する会話履歴のウィンドウサイズ。大きいほど良いですが、LLMの最大コンテンツ長に注意してください。',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      pubMedDescription:
        'https://pubmed.ncbi.nlm.nih.gov/から検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。',
      email: 'メールアドレス',
      emailTip:
        'メールアドレスは必須項目です。ここにメールアドレスを入力してください。',
      arXiv: 'ArXiv',
      arXivDescription:
        'https://arxiv.org/から検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。',
      sortBy: '並び替え',
      submittedDate: '提出日',
      lastUpdatedDate: '最終更新日',
      relevance: '関連性',
      google: 'Google',
      googleDescription:
        'https://www.google.com/から検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。これにはserpapi.comからのAPIキーが必要です。',
      bing: 'Bing',
      bingDescription:
        'https://www.bing.com/から検索を行うコンポーネントで、TopNを使用して検索結果の数を指定します。既存のナレッジベースを補完します。これにはmicrosoft.comからのAPIキーが必要です。',
      apiKey: 'APIキー',
      country: '国と地域',
      language: '言語',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'このコンポーネントは、https://scholar.google.com/ から検索結果を取得するために使用されます。通常、ナレッジベースを補完する役割を果たします。Top Nは、調整する必要がある検索結果の数を指定します。',
      yearLow: '最小年',
      yearHigh: '最大年',
      patents: '特許',
      data: 'データ',
      deepL: 'DeepL',
      deepLDescription:
        'このコンポーネントは、https://www.deepl.com/ から翻訳を取得するために使用されます。通常、より専門的な翻訳結果を提供します。',
      authKey: '認証キー',
      sourceLang: 'ソース言語',
      targetLang: 'ターゲット言語',
      gitHub: 'GitHub',
      githubDescription:
        'このコンポーネントは、https://github.com/ からリポジトリを検索するために使用されます。Top Nは、調整する検索結果の数を指定します。',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'このコンポーネントは、https://fanyi.baidu.com/ から翻訳を取得するために使用されます。通常、より専門的な翻訳結果を提供します。',
      appid: 'アプリID',
      secretKey: 'シークレットキー',
      domain: 'ドメイン',
      transType: '翻訳タイプ',
      baiduSecretKeyOptions: {
        translate: '一般翻訳',
        fieldtranslate: '専門分野翻訳',
      },
      baiduDomainOptions: {
        it: '情報技術',
        finance: '金融と経済',
        machinery: '機械製造',
        senimed: '生物医学',
        novel: 'オンライン文学',
        academic: '学術論文',
        aerospace: '航空宇宙',
        wiki: '人文社会科学',
        news: 'ニュースと情報',
        law: '法律と規制',
        contract: '契約',
      },
      baiduSourceLangOptions: {
        auto: '自動検出',
        zh: '中国語',
        en: '英語',
        yue: '広東語',
        wyw: '古典中国語',
        jp: '日本語',
        kor: '韓国語',
        fra: 'フランス語',
        spa: 'スペイン語',
        th: 'タイ語',
        ara: 'アラビア語',
        ru: 'ロシア語',
        pt: 'ポルトガル語',
        de: 'ドイツ語',
        it: 'イタリア語',
        el: 'ギリシャ語',
        nl: 'オランダ語',
        pl: 'ポーランド語',
        bul: 'ブルガリア語',
        est: 'エストニア語',
        dan: 'デンマーク語',
        fin: 'フィンランド語',
        cs: 'チェコ語',
        rom: 'ルーマニア語',
        slo: 'スロベニア語',
        swe: 'スウェーデン語',
        hu: 'ハンガリー語',
        cht: '繁体字中国語',
        vie: 'ベトナム語',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'このコンポーネントは、https://www.qweather.com/ から天気関連の情報を取得するために使用されます。天気、指数、空気質を取得できます。',
      lang: '言語',
      type: 'タイプ',
      webApiKey: 'Web APIキー',
      userType: 'ユーザータイプ',
      timePeriod: '期間',
      qWeatherLangOptions: {
        zh: '簡体字中国語',
        'zh-hant': '繁体字中国語',
        en: '英語',
        de: 'ドイツ語',
        es: 'スペイン語',
        fr: 'フランス語',
        it: 'イタリア語',
        ja: '日本語',
        ko: '韓国語',
        ru: 'ロシア語',
        hi: 'ヒンディー語',
        th: 'タイ語',
        ar: 'アラビア語',
        pt: 'ポルトガル語',
        bn: 'ベンガル語',
        ms: 'マレー語',
        nl: 'オランダ語',
        el: 'ギリシャ語',
        la: 'ラテン語',
        sv: 'スウェーデン語',
        id: 'インドネシア語',
        pl: 'ポーランド語',
        tr: 'トルコ語',
        cs: 'チェコ語',
        et: 'エストニア語',
        vi: 'ベトナム語',
        fil: 'フィリピン語',
        fi: 'フィンランド語',
        he: 'ヘブライ語',
        is: 'アイスランド語',
        nb: 'ノルウェー語',
      },
      qWeatherTypeOptions: {
        weather: '天気予報',
        indices: '天気生活指数',
        airquality: '空気質',
      },
      qWeatherUserTypeOptions: {
        free: '無料会員',
        paid: '有料会員',
      },
      qWeatherTimePeriodOptions: {
        now: '現在',
        '3d': '3日間',
        '7d': '7日間',
        '10d': '10日間',
        '15d': '15日間',
        '30d': '30日間',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'このコンポーネントは、SQL文を介して対応するリレーショナルデータベースから結果をクエリします。MySQL、PostgreSQL、MariaDBをサポートします。',
      dbType: 'データベースタイプ',
      database: 'データベース',
      username: 'ユーザー名',
      host: 'ホスト',
      port: 'ポート',
      password: 'パスワード',
      switch: 'スイッチ',
      logicalOperator: '論理演算子',
      switchOperatorOptions: {
        equal: '等しい',
        notEqual: '等しくない',
        gt: 'より大きい',
        ge: '以上',
        lt: 'より小さい',
        le: '以下',
        contains: '含む',
        notContains: '含まない',
        startWith: 'で始まる',
        endWith: 'で終わる',
        empty: '空',
        notEmpty: '空でない',
      },
      switchLogicOperatorOptions: {
        and: 'かつ',
        or: 'または',
      },
      operator: '演算子',
      value: '値',
      useTemplate: 'このテンプレートを使用',
      wenCai: 'WenCai',
      queryType: 'クエリタイプ',
      wenCaiDescription:
        'このコンポーネントは、株式、ファンドなど、幅広い金融分野の情報を取得するために使用できます。',
      wenCaiQueryTypeOptions: {
        stock: '株式',
        zhishu: '指数',
        fund: 'ファンド',
        hkstock: '香港株',
        usstock: '米国株式市場',
        threeboard: '新OTC市場',
        conbond: '転換社債',
        insurance: '保険',
        futures: '先物',
        lccp: 'ファイナンス',
        foreign_exchange: '外国通貨',
      },
      akShare: 'AkShare',
      akShareDescription:
        'このコンポーネントは、Eastmoneyウェブサイトから対応する株式のニュース情報を取得するために使用できます。',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'このコンポーネントは、提供されたティッカーシンボルに基づいて会社情報をクエリします。',
      crawler: 'Webクローラー',
      crawlerDescription:
        'このコンポーネントは、指定されたURLからHTMLソースコードをクロールするために使用できます。',
      proxy: 'プロキシ',
      crawlerResultOptions: {
        html: 'HTML',
        markdown: 'Markdown',
        content: 'コンテンツ',
      },
      extractType: '抽出タイプ',
      info: '情報',
      history: '履歴',
      financials: '財務',
      balanceSheet: '貸借対照表',
      cashFlowStatement: 'キャッシュフロー計算書',
      jin10: 'Jin10',
      jin10Description:
        'このコンポーネントは、Jin10オープンプラットフォームから金融セクターの情報にアクセスするために使用できます。クイックニュース、カレンダー、相場、参考情報などを含みます。',
      flashType: 'フラッシュタイプ',
      filter: 'フィルター',
      contain: '含む',
      calendarType: 'カレンダータイプ',
      calendarDatashape: 'カレンダーデータ形状',
      symbolsDatatype: 'シンボルデータタイプ',
      symbolsType: 'シンボルタイプ',
      jin10TypeOptions: {
        flash: 'クイックニュース',
        calendar: 'カレンダー',
        symbols: '相場',
        news: '参考情報',
      },
      jin10FlashTypeOptions: {
        '1': '市場ニュース',
        '2': '先物ニュース',
        '3': '米国・香港ニュース',
        '4': 'A株ニュース',
        '5': '商品・外国為替ニュース',
      },
      jin10CalendarTypeOptions: {
        cj: 'マクロ経済データカレンダー',
        qh: '先物カレンダー',
        hk: '香港株式市場カレンダー',
        us: '米国株式市場カレンダー',
      },
      jin10CalendarDatashapeOptions: {
        data: 'データ',
        event: 'イベント',
        holiday: '休日',
      },
      jin10SymbolsTypeOptions: {
        GOODS: '商品相場',
        FOREX: '外国為替相場',
        FUTURE: '国際市場相場',
        CRYPTO: '暗号通貨相場',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: '商品リスト',
        quotes: '最新市場相場',
      },
      concentrator: 'コンセントレーター',
      concentratorDescription:
        '上流コンポーネントからの出力を受け取り、それを下流コンポーネントへの入力として渡すコンポーネント。',
      tuShare: 'TuShare',
      tuShareDescription:
        'このコンポーネントは、主流の金融ウェブサイトから金融ニュースの概要を取得し、業界および定量的研究を支援するために使用できます。',
      tuShareSrcOptions: {
        sina: '新浪',
        wallstreetcn: '華爾街見聞',
        '10jqka': '同花順',
        eastmoney: '東方財富',
        yuncaijing: '雲財經',
        fenghuang: '鳳凰網',
        jinrongjie: '金融界',
      },
      token: 'トークン',
      src: 'ソース',
      startDate: '開始日',
      endDate: '終了日',
      keyword: 'キーワード',
      note: 'メモ',
      noteDescription: 'メモ',
      notePlaceholder: 'メモを入力してください',
      invoke: '呼び出し',
      invokeDescription:
        'このコンポーネントはリモートエンドポイント呼び出しを行うことができます。他のコンポーネントの出力をパラメータとして使用するか、定数パラメータを設定してリモート関数を呼び出します。',
      url: 'URL',
      method: 'メソッド',
      timeout: 'タイムアウト',
      headers: 'ヘッダー',
      cleanHtml: 'HTMLをクリーン',
      cleanHtmlTip:
        '応答がHTML形式であり、主要なコンテンツのみが必要な場合は、これをオンにしてください。',
      reference: '参照',
      input: '入力',
      output: '出力',
      parameter: 'パラメータ',
      howUseId: 'エージェントIDの使用方法',
      content: 'コンテンツ',
      operationResults: '操作結果',
      autosaved: '自動保存済み',
      optional: 'オプション',
      pasteFileLink: 'ファイルリンクを貼り付け',
      testRun: 'テスト実行',
      template: 'テンプレート',
      templateDescription:
        'このコンポーネントは、さまざまなコンポーネントの出力を組版するために使用されます。',
      emailComponent: 'メール',
      emailDescription: '指定されたアドレスにメールを送信',
      smtpServer: 'SMTPサーバー',
      smtpPort: 'SMTPポート',
      senderEmail: '送信者のメール',
      authCode: '認証コード',
      senderName: '送信者の名前',
      toEmail: '受信者のメール',
      ccEmail: 'CCメール',
      emailSubject: '件名',
      emailContent: '内容',
      smtpServerRequired: 'SMTPサーバーアドレスを入力してください',
      senderEmailRequired: '送信者のメールを入力してください',
      authCodeRequired: '認証コードを入力してください',
      toEmailRequired: '受信者のメールを入力してください',
      emailContentRequired: 'メール内容を入力してください',
      emailSentSuccess: 'メールが正常に送信されました',
      emailSentFailed: 'メールの送信に失敗しました',
      dynamicParameters: '動的パラメータ',
      jsonFormatTip:
        '上流コンポーネントは、次の形式のJSON文字列を提供する必要があります：',
      toEmailTip: 'to_email: 受信者のメール（必須）',
      ccEmailTip: 'cc_email: CCメール（オプション）',
      subjectTip: 'subject: メールの件名（オプション）',
      contentTip: 'content: メールの内容（オプション）',
      jsonUploadTypeErrorMessage: 'jsonファイルをアップロードしてください',
      jsonUploadContentErrorMessage: 'jsonファイルエラー',
    },
    footer: {
      profile: 'All rights reserved @ React',
    },
    layout: {
      file: 'ファイル',
      knowledge: 'ナレッジ',
      chat: 'チャット',
    },
  },
};
